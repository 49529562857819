import Vue from 'vue'
import Router from 'vue-router'
import { BasicLayout } from '@/layouts';

import Home from '../pages/Home.vue'
import AboutUs from '../pages/AboutUs.vue'
import Achievement from '../pages/achievement.vue'
import Contact from '../pages/contact.vue'
import Dontact from '../pages/dynamic.vue'
import Information from '../pages/information.vue'

Vue.use(Router)

export default new Router({
    // mode: 'history',
    routes: [
        {
            path: '/',
            component: BasicLayout,
            redirect: '/home/index',
            children: [{
                path: '/home',
                name: 'home',
                component: { render: h => h('router-view') },
                redirect: '/home/index',
                children: [{
                    path: '/home/index',
                    component: Home,
                }]
            },
            {
                path: '/aboutus',
                name: 'aboutus',
                component: { render: h => h('router-view') },
                redirect: '/aboutus/index',
                children: [{
                    path: '/aboutus/index',
                    component: AboutUs,
                }]
            },
            {
                path: '/achievement',
                name: 'achievement',
                component: { render: h => h('router-view') },
                redirect: '/achievement/index',
                children: [{
                    path: '/achievement/index',
                    component: Achievement,
                }]
            },
            {
                path: '/contact',
                name: 'contact',
                component: { render: h => h('router-view') },
                redirect: '/contact/index',
                children: [{
                    path: '/contact/index',
                    component: Contact,
                }]
            },
            {
                path: '/dynamic',
                name: 'dynamic',
                component: { render: h => h('router-view') },
                redirect: '/dynamic/index',
                children: [{
                    path: '/dynamic/index',
                    component: Dontact,
                }]
            },
            {
                path: '/information',
                name: 'information',
                component: { render: h => h('router-view') },
                redirect: '/information/index',
                children: [{
                    path: '/information/index',
                    component: Information,
                }]
            }

            
            ]
        }
    ],
    scrollBehavior() {
        return { x: 0, y: 0 }
    }
})



