<template>
    <div>
        <div ref="block1" class="Full_screen w-100 d-flex flex-column  justify-content-center align-items-center">
            <div style="min-height: 18.75rem;" class="d-flex Full_screen_text flex-column align-items-center">
                <transition>
                    <h1 class="T0_Bold text-B6" style="margin-bottom: 1.4375rem;">关于我们</h1>
                </transition>

                <transition name="welcome1">
                    <p v-show="welcomeshow" class="T3_Regular text-B6  welcome1"
                        style="width: 24rem;margin-bottom: .875rem;">
                        海南海派克工程技术咨询有限公司由重庆川维工程公司北京分公司的原班人马创立，凭借资深专家的影响力和技术优势，组建了一支国际化品质的技术服务团队。</p>
                </transition>
                <transition name="welcome1">
                    <p v-show="welcomeshow1" class="T3_Regular text-B6 welcome1"
                        style="width: 24rem;margin-bottom: .875rem;">
                        公司专注于能源化工项目的工程总承包、工程设计、设备采购与贸易、工程技术咨询等业务，面向国内外市场提供设计服务和项目管理服务，致力于构建全产业链解决方案。</p>

                </transition>

                <transition name="welcome1">
                    <p v-show="welcomeshow2" class="T3_Regular text-B6 welcome1"
                        style="width: 24rem;margin-bottom: .875rem;">
                        我们始终坚持“诚信服务、顾客满意”的宗旨，不断创新和改进，确保每个设计和管理环节都符合要求。</p>
                </transition>

                <transition name="welcome1">
                    <p v-show="welcomeshow3" class="T3_Regular text-B6 welcome1"
                        style="width: 24rem;margin-bottom: .875rem;">
                        公司秉承“以人为本，科技领先；节能减排，环保优先”的经营理念，确保员工健康和社会福祉，实现与用户的共同成长。</p>
                </transition>
            </div>

            <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/slidedown.png" width="64" :data-wow-delay="delay"
                class="img-fluid Full_screen_icon wow animate__fadeInUp" alt="...">
        </div>
        <!-- 服务内容 -->
        <section class="section" ref="block2" style="padding-top: 0;">
            <div class="Server_Content container">
                <div class="title_box text-end wow animate__fadeInUp" data-wow-offset="200">
                    <h1 class="T0_Bold text-B0 text-end Server_Content_title" style="margin: 0;">服务内容</h1>
                    <span class="T3_Regular text-B2 Server_Content_tips">石油化工专家、技术骨干及专业技术管理人员团队</span>
                </div>
                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/SERVICE.png" class="img-fluid SERVICE_icon" alt="...">

                <div class="">
                    <div class="col-lg-12 d-flex fuwunr_item_box" style="margin-top: 3.125rem;">
                        <div class="col-md-6 col-6 " style="padding: 0;">
                            <div data-wow-offset="200"
                                class=" d-flex flex-column justify-content-center align-items-start h-100 wow animate__fadeInLeftBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/f1.png" width="35" class="img-fluid fuwunr_icon"
                                    alt="...">
                                <h1 class="T1_Regular text-B0 fuwunr_title" style="margin: .2188rem 0 1.2188rem 0;">项目管理
                                </h1>
                                <span class="text-B1 T3_Regular text_left" style="width: 19.0938rem;">
                                    行业专业人才，涵盖设计、采购、施工、控制和质量等领域，致力于提供高效、全面的项目管理服务。我们确保项目按时、按质、按预算完成。
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6 col-6">
                            <div data-wow-offset="200" class=" d-flex justify-content-end wow animate__fadeInRightBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Rectangle 105.png"
                                    style="width: 15.0313rem;height: 13.5rem ;" class="img-fluid fuwunr_img" alt="...">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 d-flex fuwunr_item_box justify-content-between" style="margin-top: 49px;">

                        <div class="col-md-6 col-6">
                            <div data-wow-offset="200" class="d-flex justify-content-start wow animate__fadeInLeftBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Rectangle 159.png"
                                    style="width: 15.0313rem;height: 13.5rem ;" class="img-fluid fuwunr_img" alt="...">
                            </div>
                        </div>
                        <div class="col-md-6 col-6" style="padding: 0;">
                            <div data-wow-offset="200"
                                class="d-flex flex-column align-items-startss justify-content-center align-items-end h-100  wow animate__fadeInRightBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/f2.png" width="56" class="img-fluid fuwunr_icon1"
                                    alt="...">
                                <h1 class="T1_Regular text-B0 fuwunr_title" style="margin: .2188rem 0 1.2188rem 0;">技术服务
                                </h1>
                                <span class="text-B1 T3_Regular text_rigth text-break " style="width: 19.0938rem;">
                                    提供全面的技术转让和技术咨询服务，通过先进的技术解决方案，帮助客户提升技术水平和竞争力，实现更高效、更安全的生产运营。
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 d-flex fuwunr_item_box" style="margin-top: 49px;">
                        <div class="col-md-6 col-6" style="padding: 0;">
                            <div data-wow-offset="200"
                                class="d-flex flex-column justify-content-center align-items-start h-100 wow animate__fadeInLeftBig ">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/f3.png" width="56" class="img-fluid fuwunr_icon3"
                                    alt="...">
                                <h1 class="T1_Regular text-B0 fuwunr_title" style="margin: .2188rem 0 1.2188rem  0;">
                                    工程设计</h1>
                                <span class="text-B1 T3_Regular text_left" style="width: 19.0938rem;">
                                    团队由设计人员和资深高级工程师组成，具备独立承担化工、石油储运和炼油工程的能力。提供持续的技术支持和优化建议，帮助客户提升运营效率和竞争力。
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6 col-6">
                            <div data-wow-offset="200" class="d-flex justify-content-end  wow animate__fadeInRightBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Rectangle 160.png"
                                    style="width: 15.0313rem;height: 13.5rem ;" class="img-fluid fuwunr_img" alt="...">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 d-flex fuwunr_item_box justify-content-between" style="margin-top: 49px;">

                        <div class="col-md-6 col-6">
                            <div data-wow-offset="200" class="d-flex justify-content-start wow animate__fadeInLeftBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Rectangle 161.png"
                                    style="width: 15.0313rem;height: 13.5rem ;" class="img-fluid fuwunr_img" alt="...">
                            </div>
                        </div>
                        <div class="col-md-6 col-6" style="padding: 0;">
                            <div data-wow-offset="200"
                                class="d-flex flex-column justify-content-center align-items-startss align-items-end h-100 wow animate__fadeInRightBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/f4.png" width="56" class="img-fluid fuwunr_icon4"
                                    alt="...">
                                <h1 class="T1_Regular text-B0 fuwunr_title" style="margin: .2188rem 0 1.2188rem 0;">专业资质
                                </h1>
                                <span class="text-B1 T3_Regular text_rigth text-break" style="width: 19.0938rem;">
                                    专注于化工工程、石油及化工产品储运、炼油工程，并拥有专业乙级工程设计资质证书，确保为客户提供高标准的设计和工程服务。
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-12 d-flex fuwunr_item_box" style="margin-top: 49px;">
                        <div class="col-md-6 col-6" style="padding: 0;">
                            <div data-wow-offset="200"
                                class="d-flex flex-column justify-content-center align-items-start h-100 wow animate__fadeInLeftBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/f3.png" width="50" class="img-fluid fuwunr_icon5"
                                    alt="...">
                                <h1 class="T1_Regular text-B0 fuwunr_title" style="margin: .2188rem 0 1.2188rem 0;">课程培训
                                </h1>
                                <span class="text-B1 T3_Regular text_left" style="width: 19.0938rem;">
                                    涵盖计划控制、费用控制、HSE管理和质量管理的培训课程，为应届毕业生及行业从业人员打造，提升专业技能和行业知识。
                                </span>
                            </div>
                        </div>
                        <div class="col-md-6 col-6">
                            <div data-wow-offset="200" class="d-flex justify-content-end wow animate__fadeInRightBig">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Rectangle 162.png"
                                    style="width: 15.0313rem;height: 13.5rem ;" class="img-fluid fuwunr_img" alt="...">
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </section>

        <section class="section lilian_section" ref="block3" style="padding-bottom: 3.625rem;">
            <div class="idea container">
                <div class="title_box text-end wow animate__fadeInUp">
                    <h1 class="T0_Bold text-B0 text-end" style="margin: 0;">公司理念</h1>
                    <span class="T3_Regular text-B2">信誉是生命，诚信是根本</span>
                </div>
                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/PRINCIPLE.png" class="img-fluid PRINCIPLE_icon" alt="...">

                <div class=" purpos_box">

                    <div class="col-lg-12 d-flex flex-row flex-wrap align-items-start">
                        <div class="col-md-6 col-12 ">
                            <div data-wow-offset="300"
                                class="d-flex flex-column justify-content-center align-items-start h-100  wow animate__fadeInUp">
                                <header class="T4_Bold text-B1 d-flex align-items-center"> <span
                                        class="kongge mr-16"></span> 关于海派克</header>
                                <p class="T3_Regular text-B1 tips_box">不断完善，持续改进创新，确保每一个项目的设计管理、工程项目管理从质量、安全、环保理念等都做出精品。
                                </p>
                            </div>
                        </div>


                        <div class="col-md-6 col-10 d-flex max_count justify-content-between">
                            <div class="col-5">
                                <div class="info_item_box d-flex flex-column">
                                    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/o1.png" class="info_item_logo" alt="">
                                    <span class="T1_Regular info_item_title info_item_title1">精益求精</span>
                                    <span class="T3_Regular info_item_tips">
                                        持续改进创新，确保每一个项目的设计管理、工程项目管理从质量、安全、环保理念等都做出精品。
                                    </span>
                                    <span class="xiantiao"></span>
                                </div>
                                <div class="info_item_box d-flex flex-column">
                                    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/o2.png" class="info_item_logo" alt="">
                                    <span class="T1_Regular info_item_title info_item_title2">知名共赢</span>
                                    <span class="T3_Regular info_item_tips">
                                        建设全国知名具备项目管理和设计能力的综合性工程建设管理公司，实现海派克与用户共成长！
                                    </span>
                                    <span class="xiantiao"></span>
                                </div>
                                <div class="info_item_box d-flex flex-column">
                                    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/o3.png" class="info_item_logo" alt="">
                                    <span class="T1_Regular info_item_title info_item_title3">诚信根本</span>
                                    <span class="T3_Regular info_item_tips">
                                        诚信是我们合作的基石。我们注重在所有业务往来中保持透明和诚实，与合作伙伴共同建立互信的关系。
                                    </span>
                                    <span class="xiantiao"></span>
                                </div>
                            </div>
                            <div class="col-5 mar_top">
                                <div class="info_item_box d-flex flex-column">
                                    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/t1.png" class="info_item_logo" alt="">
                                    <span class="T1_Regular info_item_title info_item_title4">品质先锋</span>
                                    <span class="T3_Regular info_item_tips">
                                        以人为本，科技创新；遵守法律法规，节能减排环保；造福员工与社会。理从质量、安全、环保理念等都做出精品。
                                    </span>
                                    <span class="xiantiao"></span>
                                </div>
                                <div class="info_item_box d-flex flex-column">
                                    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/t2.png" class="info_item_logo" alt="">
                                    <span class="T1_Regular info_item_title info_item_title5">客户为本</span>
                                    <span class="T3_Regular info_item_tips">
                                        以客户的实际需求为向导，不断进行技术和服务创新。为客户提供持续价值
                                    </span>
                                    <span class="xiantiao"></span>
                                </div>
                                <div class="info_item_box d-flex flex-column">
                                    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/t3.png" class="info_item_logo" alt="">
                                    <span class="T1_Regular info_item_title info_item_title6">绿色环保</span>
                                    <span class="T3_Regular info_item_tips">
                                        我们致力于在所有业务活动中采取节能减排措施，减少资源消耗和环境影响。
                                    </span>
                                    <span class="xiantiao"></span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div ref="table_box_w"
                        class="col-lg-12 d-flex table_box d-flex  justify-content-center align-items-center "
                        style="margin-top: 4.875rem;">
                        <div class="col-md-4 " style="padding: 0;">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100  "
                                data-wow-offset="200">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Vector.png"
                                    style="width: 2.9063rem; height: 2.9063rem;" class="img-fluid Vector1" alt="...">
                                <h3 class="T2_Bold text-B6 max_coun">
                                    <count-to ref="refcountofore4" :start-val="0" :end-val="24"
                                        :autoplay="autoplayState" :duration="2000" />
                                    /
                                    <count-to ref="refcountofore3" :start-val="0" :end-val="7" :autoplay="autoplayState"
                                        :duration="2000" />
                                    /
                                    <count-to ref="refcountofore2" :start-val="0" :end-val="365"
                                        :autoplay="autoplayState" :duration="2000" />
                                </h3>

                                <div class="d-flex align-items-center">
                                    <span class=" kongge "></span>
                                    <span class="T4_Bold text-B6 ml_20">技术咨询</span>
                                </div>
                                <p class="T5_Regular text-B6 tips">我们为客户提供最满意服务</p>
                            </div>
                        </div>
                        <div class="col-md-4 " style="padding: 0;">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100  "
                                data-wow-offset="200">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Group 157.png"
                                    style="width: 1.8125rem;height: 2.9063rem;" class="img-fluid Vector2" alt="...">
                                <h3 class="T2_Bold text-B6 max_coun">
                                    <count-to ref="refcountofore1" :start-val="0" :end-val="15"
                                        :autoplay="autoplayState" :duration="2000" />
                                    年
                                </h3>
                                <div class="d-flex align-items-center">
                                    <span class=" kongge"></span>
                                    <span class="T4_Bold text-B6 ml_20">专业领域</span>
                                </div>

                                <p class="T5_Regular text-B6 tips">15年如一日的专业项目积累</p>
                            </div>
                        </div>
                        <div class="col-md-4 " style="padding: 0;">
                            <div class="d-flex flex-column justify-content-center align-items-center h-100  "
                                data-wow-offset="200">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/Vector 2.png"
                                    style="width: 3.0938rem; height: 3.0625rem;" class="img-fluid Vector3" alt="...">
                                <h3 class="T2_Bold text-B6 max_coun">团队</h3>
                                <div class="d-flex align-items-center">
                                    <span class=" kongge"></span>
                                    <span class="T4_Bold text-B6 ml_20">专家背书</span>
                                </div>

                                <p class="T5_Regular text-B6 tips">石油化工资深专家团队管理</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>

    </div>
</template>
<script>
import CountTo from 'vue-count-to'
export default {
    name: "AboutUs",
    components: {
        CountTo
    },
    data() {
        return {
            btnFlag: false,
            welcomeshow: false,
            welcomeshow1: false,
            welcomeshow2: false,
            welcomeshow3: false,
            delay: '6.5s',
            autoplayState: false
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.$wow.init()
        })
        setTimeout(() => {
            this.welcomeshow = true
        }, 500);
        setTimeout(() => {
            this.welcomeshow1 = true
        }, 2500);
        setTimeout(() => {
            this.welcomeshow2 = true
        }, 4500);
        setTimeout(() => {
            this.welcomeshow3 = true
        }, 6500);
        window.addEventListener("scroll", this.scrollToTop, true);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scrollToTop, true);
    },
    methods: {

        scrollToTop() {
            let that = this;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;


            let y = this.$refs.table_box_w.getBoundingClientRect().y

            that.scrollTop = scrollTop;
            this.welcomeshow = true
            this.welcomeshow1 = true
            this.welcomeshow2 = true
            this.welcomeshow3 = true
            this.delay = '0s'
            if (that.scrollTop > 50) {
                that.btnFlag = true;
            } else {
                that.btnFlag = false;
            }
            if (y <= 650) {

                if (!this.autoplayState) {
                    this.$refs.refcountofore1.start()
                    this.$refs.refcountofore2.start()
                    this.$refs.refcountofore3.start()
                    this.$refs.refcountofore4.start()
                }
                this.autoplayState = true
            }
        },
    },
};
</script>
<style lang="less" scoped>
.Full_screen {
    position: relative;
    height: 100vh;
    background-image: url('http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/AboutUs_bj.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .Full_screen_icon {
        position: absolute;
        bottom: 0;
    }
}

.Server_Content {
    padding-top: 3.125rem;

    .SERVICE_icon {
        position: absolute;
        right: 0;
        top: 1.25rem;
        height: 213px;
        z-index: -1;
        width: 39.1875rem;
        height: 9.7813rem;
        object-fit: scale-down;
    }

    .text_left,
    .text_rigth {
        position: relative;
    }

    .text_left::before {
        content: " ";
        display: inline-block;
        width: .25rem;
        height: .25rem;
        background: #2D9CDB;
        border-radius: 50%;
        position: absolute;
        top: .3125rem;
        left: -0.4688rem;
    }

    .text_rigth::before {
        content: " ";
        display: inline-block;
        width: .25rem;
        height: .25rem;
        background: #2D9CDB;
        border-radius: 50%;
        position: absolute;
        top: .3125rem;
        right: -0.1563rem;
    }
}

.lilian_section {
    background: #E8F2F3;
    padding: 0;
    // position: relative;
}

.idea {
    padding-top: 4rem;

    .title_box {
        position: relative;
        height: 3.375rem;
        right: 0;
        z-index: 2;
    }

    .PRINCIPLE_icon {
        position: absolute;
        right: 0;
        top: .625rem;
        height: 213px;
        z-index: 1;
        width: 40.1875rem;
        height: 9.7813rem;
        right: -0.9375rem;
        object-fit: scale-down;
        z-index: 1;
    }


    .table_box {
        padding: 1.6875rem 5rem 1.875rem 5rem;
        border-radius: .7813rem;
        background: #171717;

        .max_coun {
            margin: 0;
            margin-top: 1.125rem;
            height: 1.5625rem;
            line-height: 1.5625rem;
            // margin-bottom: .4688rem;
        }

        .tips {
            margin-top: .9688rem;
        }
    }
}

// .welcome1 {
//     opacity: 0;
//     animation: fadeIn 4s ease forwards;
// }

// @keyframes fadeIn {
//     from {
//         opacity: 0;
//     }

//     to {
//         opacity: 1;
//     }
// }

.welcome1-enter-active,
.welcome1-leave-active {
    transition: opacity 2s;
}

.welcome1-enter,
.welcome1-leave-to {
    opacity: 0;
}

.purpos_box {
    position: relative;
    z-index: 99;

    .kongge {
        display: inline-block;
        width: .625rem;
        height: .625rem;
        background: #2F80ED;
    }

    .max_title {
        margin-top: 55px;
        margin-bottom: 15px;
    }

    .tips_box {
        width: 10.6563rem;
        margin-top: .375rem;
        letter-spacing: .125rem;
    }

    .line {
        margin-top: 27px;
        width: 58px;
        height: 0px;
        border-radius: 0px 0px 0px 0px;
        border: 3px solid #000000;
    }


    .mar_top {
        margin-top: 5.8125rem;
    }

    .info_item_box {
        // position: absolute;
        background-image: url('http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/new_bj_img.png');
        background-size: 100% 100%;
        width: calc(12.4688rem - 1.375rem);
        height: 17rem;
        padding-left: 1.375rem;
        margin-bottom: 2.5625rem;

        .info_item_logo {
            width: 2.6563rem;
            height: 2.5313rem;
            margin-top: 1.125rem;
            margin-bottom: 4.6875rem;
            object-fit: fill !important;
        }

        .info_item_title {
            margin-bottom: .7813rem;
        }

        .info_item_tips {
            width: 8.9688rem;
        }

        .xiantiao {
            display: inline-block;
            width: 1.8125rem;
            border: .0938rem solid #000000;
            margin-top: .8125rem;
        }
    }
}


@media (min-width: 200px) and (max-width: 768px) {
    .Full_screen {
        background-size: cover;

        .Full_screen_text {
            padding: 0 .75rem;

            .welcome1 {
                width: 20.25rem !important;
                line-height: 1.6265rem;
            }
        }
    }

    .Server_Content {
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        .Server_Content_title {
            font-size: 1.5rem;
        }

        .Server_Content_tips {
            display: inline-block;
            width: 9.375rem;
            font-size: .75rem;
        }

        .SERVICE_icon {
            width: 12.875rem;
            height: 3.4375rem;
            top: 4.7059rem  ;
        }

        .fuwunr_item_box {
            align-items: flex-end;

            .fuwunr_icon {
                width: 1.125rem;
                height: 1.4375rem;
            }

            .fuwunr_icon1 {
                width: 1.5625rem;
                height: 1.4375rem;
            }

            .fuwunr_icon3 {
                width: 1.4375rem;
                height: 1.1875rem;
            }

            .fuwunr_icon4 {
                width: 1rem;
                height: 1.3125rem;
                object-fit: fill;
            }

            .fuwunr_icon5 {
                width: 1.5625rem;
                height: 1.3125rem;
            }

            .fuwunr_title {
                font-size: .875rem !important;
                height: 1.3125rem;
                line-height: 1.3125rem;
                margin-top: .625rem !important;
                margin-bottom: 0 !important;
            }

            .text_left {
                width: 11.1875rem !important;

                &::before {
                    top: .3125rem;
                    left: -0.5125rem;
                }
            }

            .text_rigth {
                width: 11.1875rem !important;
                text-align: left;

                &::before {
                    top: .3125rem;
                    left: -0.5125rem;
                }
            }

            .fuwunr_img {
                width: 6.875rem !important;
                height: 6.1875rem !important;
            }
        }

        .align-items-startss {
            align-items: flex-start !important;
        }

    }

    .lilian_section {
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        .PRINCIPLE_icon {
            width: 16.25rem;
            height: 3.4375rem;
            top: 2.6875rem;
            right: -1.625rem;
        }

        .idea {
            margin-left: 0;
            padding-right: 0;
        }

        .max_count {
            margin: 0 auto;
        }

        .info_item_box {
            margin-top: 2.75rem;
            // position: absolute;
            background-image: url('http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/new_min_bj.png');
            background-size: 100% 100%;
            width: calc(8.3125rem - .375rem);

            height: 13.5rem;
            padding-left: .875rem;
            margin-bottom: 2rem;
            position: relative;


            .info_item_logo {
                width: 2rem;
                height: 1.875rem;
                margin-top: .75rem;
                margin-bottom: 1.5rem;
            }

            .info_item_title {
                margin-bottom: 0;
                font-size: .875rem;
                margin-left: .4375rem;
                height: 1.25rem;
                line-height: 1.25rem;

            }

            .info_item_tips {
                width: 5.9375rem;
                font-size: .625rem;
                margin-left: .4375rem;
            }

            .xiantiao {
                position: absolute;
                display: inline-block;
                width: 1.875rem;
                border: .0625rem solid #808080;
                // margin-top: .6024rem;
                margin-left: .4375rem;
                bottom: .9036rem;
            }
        }

        .mar_top {
            margin-top: 4.625rem;
        }
    }

    .table_box {
        margin-top: 0 !important;
        padding: 1rem 1.9375rem .9375rem 1.375rem !important;
        justify-content: space-between !important;

        .Vector1 {
            width: 1.6875rem !important;
            height: 1.6875rem !important;
        }

        .Vector2 {
            width: 1.125rem !important;
            height: 1.75rem !important;
        }

        .Vector3 {
            width: 1.875rem !important;
            height: 1.875rem !important;
        }

        .max_coun {
            font-size: .875rem;
            margin-top: .8125rem !important;
        }

        .kongge {
            width: .375rem;
            height: .375rem;
        }

        .T4_Bold {
            font-size: .625rem;
            margin-left: .5rem !important;
        }

        .tips {
            font-size: .375rem;
            margin: 0 !important;
            margin-top: .625rem !important;
        }
    }

    .info_item_title1 {
        margin-bottom: 1.8072rem !important;
    }

    .info_item_title2 {
        margin-bottom: 1.3253rem !important;
    }

    .info_item_title3 {
        margin-bottom: 1.0843rem !important;
    }

    .info_item_title4 {
        margin-bottom: .9036rem !important;
    }

    .info_item_title5 {
        margin-bottom: 2.2892rem !important;
    }

    .info_item_title5 {
        margin-bottom: 1.6867rem !important;
    }
}
</style>