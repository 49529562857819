<template>
  <div>
    <section class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 footer_left">
            <div class="row">
              <div class="col-md-2">
                <h4
                  class="T3_Bold font-weight-normal text-white"
                  @click="goAssignBlock('block1', 30, '/home')"
                >
                  首页
                </h4>
              </div>
              <div class="col-md-2">
                <h4 class="T3_Bold font-weight-normal text-white">关于我们</h4>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a
                      @click="goAssignBlock('block1', 30, '/aboutus')"
                      class="footer-link text-B6 T5_Regular"
                      >公司简介</a
                    >
                  </li>
                  <li>
                    <a
                      @click="goAssignBlock('block2', 30, '/aboutus')"
                      class="footer-link text-B6 T5_Regular"
                      >服务内容</a
                    >
                  </li>
                  <li>
                    <a
                      @click="goAssignBlock('block3', 30, '/aboutus')"
                      class="footer-link text-B6 T5_Regular"
                      >公司理念</a
                    >
                  </li>
                  <!-- <li><a href="#" class="footer-link text-B6 T5_Regular">合作伙伴</a></li> -->
                </ul>
              </div>
              <div class="col-md-2">
                <h4 class="T3_Bold font-weight-normal text-white">公司业绩</h4>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a
                      @click="
                        goAssignBlock('block2', 30, '/achievement', {
                          topindex: 1,
                        })
                      "
                      class="footer-link text-B6 T5_Regular"
                      >项目管理</a
                    >
                  </li>
                  <li>
                    <a
                      @click="
                        goAssignBlock('block1', 30, '/achievement', {
                          topindex: 2,
                        })
                      "
                      class="footer-link text-B6 T5_Regular"
                      >设计业务</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-2">
                <h4 class="T3_Bold font-weight-normal text-white">企业动态</h4>
                <ul class="list-unstyled footer-sub-menu">
                  <li>
                    <a
                      @click="goAssignBlock('block1', 30, '/dynamic')"
                      class="footer-link text-B6 T5_Regular"
                      >新闻中心</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-2">
                <h4
                  class="T3_Bold font-weight-normal text-white"
                  @click="goAssignBlock('block1', 30, '/contact')"
                >
                  联系我们
                </h4>
                <!-- <ul class="list-unstyled footer-sub-menu">
                  <li><a href="#" class="footer-link text-B6 T5_Regular">人材招聘</a></li>
                  <li><a href="#" class="footer-link text-B6 T5_Regular">课程招生</a></li>
                  <li><a href="#" class="footer-link text-B6 T5_Regular">联系方式</a></li>
                </ul> -->
              </div>
            </div>
          </div>
          <div class="col-lg-2 footer_left">
            <div class="row">
              <div class="col-md-12 mb-1">
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/telephone.png"
                  width="15"
                  height="15"
                  class="img-fluid"
                  alt="..."
                />

                <span
                  class="text-justify text-B7 T5_Regular"
                  style="margin-left: 1rem"
                  >86+13801074881</span
                >
              </div>

              <div class="col-md-12">
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/mailbox.png"
                  width="15"
                  height="15"
                  class="img-fluid"
                  alt="..."
                />

                <span
                  class="text-justify text-B7 T5_Regular"
                  style="margin-left: 1rem"
                  >hpec4881@163.com</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="row">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/recruit_image.png"
                class="img-fluid"
                alt="..."
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="T5_Regular mb-0 text-B7">
                ©海南海派克有限公司版权所有 京ICP备05234430号-1
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="footer h5_footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 offset-lg-1 footer_left">
            <div class="row">
              <div class="col-md-3 col-12 p0">
                <h4
                  @click="goAssignBlock('block1', 30, '/home')"
                  class="T58_Bold font-weight-normal text-white"
                >
                  首页
                  <!-- <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/footer_arr_left.png" class="footer_arr_left_icon"
                    :class="index == 0 ? 'actives' : ''" alt="" /> -->
                </h4>
                <!-- <ul class="list-unstyled footer-sub-menu" :class="index == 0? 'active' : ''">
                </ul> -->
              </div>
              <div class="col-md-3 col-12 p0">
                <h4
                  @click="indexChange(1)"
                  class="T58_Bold font-weight-normal text-white d-flex align-items-sm-center"
                >
                  <span>关于我们</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/footer_arr_left.png"
                    class="footer_arr_left_icon"
                    :class="index == 1 ? 'actives' : ''"
                    alt=""
                  />
                </h4>
                <ul
                  class="list-unstyled footer-sub-menu"
                  :class="index == 1 ? 'active' : ''"
                >
                  <li>
                    <a
                      @click="goAssignBlock('block1', 30, '/aboutus')"
                      class="footer-link text-B6"
                      >公司简介</a
                    >
                  </li>
                  <li>
                    <a
                      @click="goAssignBlock('block2', 30, '/aboutus')"
                      class="footer-link text-B6"
                      >服务内容</a
                    >
                  </li>
                  <li>
                    <a
                      @click="goAssignBlock('block3', 30, '/aboutus')"
                      class="footer-link text-B6"
                      >公司理念</a
                    >
                  </li>
                  <!-- <li><a @click="goAssignBlock('block1', 30, '/aboutus')" class="footer-link text-B6 ">合作伙伴</a></li> -->
                </ul>
              </div>
              <div class="col-md-3 col-12 p0">
                <h4
                  @click="indexChange(2)"
                  class="T58_Bold font-weight-normal text-white"
                >
                  <span>公司业绩</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/footer_arr_left.png"
                    class="footer_arr_left_icon"
                    :class="index == 2 ? 'actives' : ''"
                    alt=""
                  />
                </h4>
                <ul
                  class="list-unstyled footer-sub-menu"
                  :class="index == 2 ? 'active' : ''"
                >
                  <li>
                    <a
                      @click="
                        goAssignBlock('block2', 30, '/achievement', {
                          topindex: 1,
                        })
                      "
                      class="footer-link text-B6"
                      >项目管理</a
                    >
                  </li>
                  <li>
                    <a
                      @click="
                        goAssignBlock('block1', 30, '/achievement', {
                          topindex: 2,
                        })
                      "
                      class="footer-link text-B6"
                      >设计业务</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-3 col-12 p0">
                <h4
                  @click="indexChange(3)"
                  class="T58_Bold font-weight-normal text-white"
                >
                  <span>企业动态</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/footer_arr_left.png"
                    class="footer_arr_left_icon"
                    :class="index == 3 ? 'actives' : ''"
                    alt=""
                  />
                </h4>
                <ul
                  class="list-unstyled footer-sub-menu"
                  :class="index == 3 ? 'active' : ''"
                >
                  <li>
                    <a
                      @click="goAssignBlock('block1', 30, '/dynamic')"
                      class="footer-link text-B6"
                      >新闻中心</a
                    >
                  </li>
                </ul>
              </div>
              <div class="col-md-3 col-12 p0">
                <h4
                  @click="goAssignBlock('block1', 30, '/contact')"
                  class="T58_Bold font-weight-normal text-white"
                >
                  联系我们
                  <!-- <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/footer_arr_left.png" class="footer_arr_left_icon"
                    :class="index == 4 ? 'actives' : ''" alt="" /> -->
                </h4>
                <ul
                  class="list-unstyled footer-sub-menu"
                  :class="index == 4 ? 'active' : ''"
                >
                  <!-- <li><a href="#" class="footer-link text-B6 ">人材招聘</a></li>
                  <li><a href="#" class="footer-link text-B6 ">课程招生</a></li>
                  <li><a href="#" class="footer-link text-B6 ">联系方式</a></li> -->
                </ul>
              </div>
            </div>
          </div>
          <div class="col-lg-2 footer_left">
            <div class="row" style="padding: 0 2.5rem">
              <div class="col-md-12 mb-2 col-6 p0">
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/telephone.png"
                  width="15"
                  height="15"
                  class="img-fluid"
                  alt="..."
                />
                <span
                  class="text-justify text-B7 tips_text"
                  style="margin-left: 1rem"
                  >86+13801074881</span
                >
              </div>

              <div class="col-md-12 col-6 p0">
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/mailbox.png"
                  width="15"
                  height="15"
                  class="img-fluid"
                  alt="..."
                />

                <span
                  class="text-justify text-B7 tips_text"
                  style="margin-left: 1rem"
                  >hpec4881@163.com</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="mb-5">
              <div
                class="row"
                style="padding: 0 0.5rem"
                @click="goAssignBlock('block1', 30, '/contact')"
              >
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/recruit_image.png"
                  class="img-fluid"
                  alt="..."
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <div class="text-center">
              <p class="T7_Regular mb-0 text-B7">
                ©海南海派克有限公司版权所有 京ICP备05234430号-1
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "GlobalFooter",
  data() {
    return {
      index: null,
    };
  },
  methods: {
    indexChange(index) {
      if (this.index != index) {
        this.index = index;
      } else {
        this.index = null;
      }
    },
    /**
     * 滚动到指定位置
     */
    goAssignBlock(el, speed, url, query) {
      let path = this.$route.path;

      if (path.indexOf("url") == -1) {
        this.$router.push({ path: url, query: query });
      }

      this.$emit("goAssignBlock", el, speed, url);
    },
  },
};
</script>

<style lang="less" scoped>
.h5_footer {
  display: none;
}

.footer .footer-sub-menu li {
  padding: 0;
  height: .875rem;
  line-height: .875rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: .5rem
}

@media (min-width: 200px) and (max-width: 768px) {
  .footer {
    display: none;
  }

  .h5_footer {
    display: block;
    // padding-top: 1.8072rem ;

    .list-unstyled {
      // display: none;
      margin-bottom: 0;
      height: 0px;
      overflow: hidden; // 让li的内容隐藏，实现从上往下显示的效果
      transition: all .5s;
      font-weight: 400;
      font-family: "HarmonyOS Sans TC Regular" !important;

      &.active {
        // display: block;
        height: 4.75rem;
        transition: all 0.8s // transition: .8s height;
      }
    }

    .footer-sub-menu li {
      font-size: .75rem;
      padding: .4819rem;
      padding-bottom: .6024rem;
    }

    .footer_left {
      padding-top: 0;

      .tips_text {
        font-size: .75rem;
        margin-left: .7229rem !important;
        font-weight: 400 !important;
        font-family: "HarmonyOS Sans TC Regular" !important;
      }
    }

    .font-weight-normal {
      font-size: 14px;
      // border-bottom: .0602rem solid #fff;
      border-bottom: 1px solid rgba(255, 255, 255, 0.41);
      font-weight: 400 !important;
      font-family: "HarmonyOS Sans TC Regular" !important;
      padding: calc(var(--bs-gutter-x) * 0.5);
      padding-bottom: .6024rem;
      align-items: center;

      .footer_arr_left_icon {
        width: .3125rem;
        height: .625rem;
        margin-left: .625rem;
        transform: rotate(0deg);
        transition: all 0.5s;

        &.actives {
          transform: rotate(90deg);
          transition: all 0.5s // transition: .8s height;
        }
      }
    }
  }
}
</style>
