<template>
    <div>
        <header class="h5_max_header pc_max_header">
            <div class="jinbi_box d-flex align-items-center">
                <span class="T7_Medium text-B3 jinbi_name">企业动态</span>
                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/information/min_arr_rig.png" class="jinbi_icon" alt="">
                <span class="T7_Medium text-B3 jinbi_name">项目资讯</span>
                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/information/min_arr_rig.png" class="jinbi_icon" alt="">
                <span class="T7_Medium text-B3 jinbi_name">{{ info.name }}</span>
            </div>

            <span class="T1_Medium max_title">{{ info.name }}</span>


            <div class="logo_box d-flex align-items-center justify-content-md-center">
                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/information/min_logo.png" class="min_logo_icon" alt="">
                <span class="xian"></span>
                <div class="time_box">
                    <span class="T5_Regular text-B3 time_name">发表于</span>
                    <span class="T5_Regular time_day">2020年3月21日</span>
                </div>
            </div>
        </header>

        <section class="section " style="padding-top: 2.1875rem;padding-bottom: 0;">
            <div class="container text_container">

                <div v-for="(item, index) in info.content" :key="index">
                    <span v-if="item.type == 1" class="T5_Regular text-B1 text_p">
                        {{ item.url }}
                    </span>
                    <!-- {{ item.url }} -->
                    <img :src="item.url" class="project_image" v-if="item.type == 2" alt="">
                </div>




            </div>
        </section>



        <section class="section " style="padding-top: 2.7813rem;">
            <div class="container footer_box">

                <div class="col-12">
                    <span class="T2_Regular text-B3 footer_title">相关项目文章</span>
                    <div class="d-flex flex-wrap">
                        <div class="col-lg-6 col-10  project_item d-flex flex-column justify-content-center wow animate__backInUp"
                            data-wow-offset="200" @click="infoFun(index)" v-for="(item, index) in list" :key="index">

                            <img :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_${index + 1}.png`"
                                class="image_logo" alt="">
                            <span class="text-B2 type_name T5_Regular"> 项目资讯</span>
                            <div class="info">
                                <h5 class="T2_Bold text-B0 project_name " style="margin: 0;">{{ item.name }}</h5>
                            </div>

                            <div class="ljie_box">
                                <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/farrow.png" class="farrow_icon" alt="">
                                <span class="T4_Bold ljie">了解更多</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- contact -->
    </div>
</template>


<script>
// import Data from './data.json'
export default {
    name: "AboutUs",
    data() {
        return {
            projectList: [
                {
                    "name": "风机一响，黄金万两！中科炼化一重量级装置成功启用",
                    "content": [
                        {
                            "url": "8月24日19时28分，中科炼化420万吨/年重油催化裂化装置喷油成功，打通了公司炼油全流程关键节点，标志着再生器烧焦能力世界最大的、国产化率最高的催化裂化装置一次开车成功。",
                            "type": 1
                        },
                        {
                            "url": "催化裂化装置是炼油的核心装置，从工艺技术到核心设备实现完全国产化，主体设备操作弹性120%，加工能力可达到500万吨/年，是国内催化裂化装置大型化的里程碑工程。该装置反应—再生两器型式采用高低并列式，反应部分采用石油化工科学研究院的MIP专利工艺技术，再生工艺采用我国具有自主知识产权的、世界先进的快速床—湍流床主风串联再生技术，是全国首套采用双烟机并列操作的催化裂化装置。烟气脱硝后氮氧化物含量小于44 mg/Nm3，远低于国家大气污染物特别排放限值小于100 mg/Nm3的标准，可满足远期更苛刻的排放标准要求。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_1.png',
                            "type": 2
                        },
                        {
                            "url": "为实现安全环保一次开车成功目标，炼油一部催化裂化区域团队在集团公司开工专家的指导下，连续4天4夜奋战在装置开工一线，精心做好催化剂流化等前期装置开车准备工作，为装置顺利开车奠定了基础。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_1.1.png',
                            "type": 2
                        },
                        {
                            "url": "当天19时28分，外操陆续打开原料油喷嘴，内操进行精心调整。21时24分，装置进料量稳定在270吨/时。目前，装置运行平稳。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "攻坚场景美如画 最美一线石化人",
                    "content": [
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_2.1.png',
                            "type": 2
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_2.2.png',
                            "type": 2
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_2.3.png',
                            "type": 2
                        }
                    ]
                },
                {
                    "name": "海派克参管中科EVA装置中交",
                    "content": [
                        {
                            "url": "2021年12月3日，中科炼化一体化项目再传捷报，经石化四建工程项目管理团队湛江中科炼化PMC项目管理部（海派克）EVA项目组及各参建单位三年多的辛勤奋战，中科炼化10万吨/年EVA装置顺利中交！",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_3.1.png',
                            "type": 2
                        },
                        {
                            "url": "中科炼化10万吨/年EVA装置是以乙烯、醋酸乙烯为原料，以有机过氧化物为引发剂，通过反应釜高效搅拌器的搅拌，在高压状态下生产乙烯-醋酸乙烯共聚EVA产品。该装置主要由乙烯压缩单元、聚合单元、高/低压循环分离单元、挤压造粒单元、风送脱气单元、废气处理单元、醋酸乙烯回收精制单元以及配套的冷冻水机组等辅助设施组成。",
                            "type": 1
                        },
                        {
                            "url": "为确保中科炼化10万吨/年EVA装置按期中交，石化四建工程项目管理团队湛江中科PMC项目管理部（海派克）EVA项目组以高压超高压管道系统安装、大机组试车为关键施工线路，精心组织10万吨/年EVA装置的施工管理工作，首先积极督促EPC总包单位加快推进高压、超高压进口管道材料的采购工作，原计划所有进口超高压管道在2020年到货，因突发疫情影响，进口材料到货滞后严重制约着施工进度，经与采购厂商反复沟通协调，第一批进口材料在今年5月份才运抵现场，又经过近两个月的催交催运，7月份所有进口高压管道材料才全部到货，为EVA装置超高压管道系统安装创造了条件；其次，面临超高压管道系统进口材料不能按期到货的困难，EVA项目组及时督促总包调整施工计划，要求施工单位积极抢上低压工艺管道的预制安装，加快电气仪表安装调试步伐，确保10万吨/年EVA装置设备安装、低压管道试压、电气仪表调试按期完成；最后，积极督促施工单位尽快完成压缩机机间管线回装，组织一次压缩机和二次压缩机油运，确保大机组试车工作全面展开。",
                            "type": 1
                        },
                        {
                            "url": "经石化四建工程项目管理团队湛江中科PMC项目管理部（海派克）EVA项目组的不懈努力，截至目前，一、二次压缩机单机试车已全部完成，挤压机已完成投料试车，冰机抽真空等工作已圆满结束，预冷器、中冷器、深冷器等设备试压工作已顺利完成，总共714段高压管段已全部安装完，18段调整段已全部安装完，总共1568副法兰已安装完、紧固完，高压管道58个试压包已全部试压完，为EVA装置打通全流程顺利中交打下了坚实的基础。随着10万吨/年EVA装置的顺利中交，将为中科炼化一体化项目画上了一个圆满的句号。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_3.2.png',
                            "type": 2
                        },
                        {
                            "url": "2020年6月位于广东省湛江市东海岛的中国石化中科炼化一体化项目正式建成投产。",
                            "type": 1
                        },
                        {
                            "url": "该项目是中国石化“十三五”期间投产的最大炼化一体化项目，包括1000万吨/年炼油、80万吨/年乙烯项目及相关辅助配套工程。该项目预计年产值超过600亿元，可实现年利税260多亿元，拉动超2000亿元下游产业，将成为湛江地区经济发展的“新引擎”，为粤港澳大湾区建设注入强劲动力。",
                            "type": 1
                        },
                        {
                            "url": "为落实党中央、国务院调整优化重大生产力布局的战略部署，中国石化实施炼化基地化发展战略。“十三五”期间，中国石化宣布将投资2000亿元，打造茂湛、镇海、上海和南京4个技术先进、具有国际竞争力的世界级炼化基地，推进产业升级，向价值链中高端进军，提高发展质量和效益，并率先启动茂湛一体化基地建设。中科炼化是茂湛基地重要项目之一，是中国石化在新时代建设的标志性炼化工程，是国家“一带一路”建设及能源化工发展战略的重要支点，也是广东省构建“一核一带一区”区域发展新格局、打造现代化沿海经济带重要发展极的重点工程。",
                            "type": 1
                        },
                        {
                            "url": "中科炼化主要生产国6汽柴油、航空煤油及高端化工产品，是目前国内生产装备国产化率最高的炼化一体化项目。国内自主研发的先进炼化生产装备技术在中科炼化得到广泛应用，国产化率超过95%。该项目与邻近企业通过循环经济形成优势互补，氧气、氮气、氢气等公用工程物料实现互供互备；环保排放执行国际先进标准，各项环保指标均处于国内同行业领先水平。自建港口码头与炼化装置的直线距离仅1000米，年吞吐能力达3400万吨，是目前国内一次建成规模最大、结构形式和使用功能最多的港口。成品油及化工产品可直达华南市场，辐射大西南，还可利用临港优势，直接出口面向国际市场。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "海派克海南自贸大楼项目开工仪式",
                    "content": [
                        {
                            "url": "9月16日，中国石化自贸大厦项目开工仪式在海口市江东新区正式举行。海南省政协主席李国梁，海口市市委常委、副市长、江东新区管理局党委书记鞠磊，集团公司工程部总经理吴文信，发展计划部副总经理、集团公司首席专家何建英，公司执行董事、党委书记张强，党委副书记、纪委书记、工会主席梁军超，公司首席专家王敦庆，工程管理部副经理、海南项目团队负责人朱森红等参加开工仪式。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_4.1.png',
                            "type": 2
                        },
                        {
                            "url": "# 海南自由贸易港新地标<br/>海南正努力成为新时代全面深化改革开放的新标杆，以供给侧结构性改革为主线，建设自由贸易试验区和中国特色自由贸易港，着力打造中国全面深化改革开放试验区、国家生态文明试验区、国际旅游消费中心、国家重大战略服务保障区。中国石化自贸大厦作为中国石化跨区域总部基地，是江东新区的地标建筑，也是中国石化重点工程项目，更是中国石化参与海南自贸港建设的重要标志。该项目建成后将充分依托自贸区优惠政策、区位优势等便利条件，发展能源国际贸易、金融服务、电子商务等高端产业，带动中国石化绿色能源产业和现代商业模式的转型升级与快速发展。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_4.2.png',
                            "type": 2
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_4.3.png',
                            "type": 2
                        },
                        {
                            "url": "项目管理团队组建<br/>中国石化自贸大厦项目采用项目建设领导小组领导下的“联合项目部+监理+承包商（E+P+C）”建设管理模式，联合项目管理部由公司海南项目团队和海南赛诺佩克有限公司组成。朱森红经总部工程部任命担任联合项目部副总经理，其他设计、控制、施工等主要部门负责人均由公司派遣人员担任。",
                            "type": 1
                        },
                        {
                            "url": "2021年5月，海南项目团队抵达海南，迅速进入工作状态，紧锣密鼓地开展项目前期准备工作。此时距集团公司与海南省政府商定的项目开工时间仅有四个月。为了实现项目真正意义开工，项目团队发挥艰苦奋斗、迎难而上的工作作风，挑灯夜战抢进度，加班加点赶工期，在较短的时间内克服了人员不足、地方行政许可复杂等诸多不利因素，积极调研、统筹策划、扎实推进，顺利实现开工目标，得到了总部工程部领导及业主单位的肯定，展现了项目管理公司的良好形象。",
                            "type": 1
                        },
                        {
                            "url": "在开工仪式上，张强代表公司发言。张强表示，项目管理公司始终秉承“客户利益优先、管理创造价值、以人才为中心、安全质量为魂”的价值观，坚持以建设“绿色工程、安全工程、精品工程、效益工程、阳光工程”为目标。此次参与中国石化自贸大厦的项目管理工作，深感责任重大、使命光荣。公司领导班子高度重视中国石化自贸大厦项目，委派项目管理经验丰富的团队参与项目建设，努力实现高质量履约，以满足各方要求。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_4.4.png',
                            "type": 2
                        },
                        {
                            "url": "吴文信代表总部工程部提出要求。吴文信指出，项目管理部要牢固树立红线意识和底线思维。要认真贯彻中国石化安全生产相关要求，做好现场建设防风险、除隐患、遏事故的安全管理，加强现场直接作业管理管控力度。同时，以争创国优为项目质量管理工作的驱动力，以“识别大风险、消除大隐患、杜绝大事故”为安全管理工作的主线，努力实现项目“零伤害、零污染、零事故”的终极安全目标，全面提升中国石化自贸大厦项目管理水平。",
                            "type": 1
                        },
                        {
                            "url": "吴文信强调，“百年奋进路，扬帆再出发”，项目管理公司要承压而上、奋勇前进，按照总体统筹控制计划，切实增强工作的预见性、主动性，以强烈的敬业精神、奉献精神和责任心，脚踏实地，勇担重任，自加压力，全力以赴做好项目建设工作，为项目创国家优质工程奠定坚实的基础。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "海峡两岸合资，古雷项目投产!",
                    "content": [
                        {
                            "url": "中国石化18日宣布，海峡两岸最大石化产业合作项目——古雷炼化一体化项目建成投产，乙烯等主体生产装置打通全流程，实现一次投料开车成功，并产出合格产品。预计年产值超过260亿元，可实现年利税40亿元，拉动上千亿元下游产业。",
                            "type": 1
                        },
                        {
                            "url": "中国石化介绍，古雷炼化一体化项目位于福建漳州，是以生产高端化工产品为主的石化项目。项目一期工程总投资278亿元，主要包括百万吨级乙烯、60万吨/年苯乙烯等9套化工装置、配套公用工程，以及年吞吐能力780万吨的码头泊位等，主要生产聚丙烯、乙二醇、苯乙烯等18种主产品，产品总量预计达300万吨/年。",
                            "type": 1
                        },
                        {
                            "url": "该项目是全国七大石化产业基地之一古雷石化基地的龙头项目，由中国石化福建炼油化工有限公司和台资企业旭腾投资有限公司各占50%股份合资成立的福建古雷石化有限公司建设。2017年12月项目一期工程开工建设，2021年5月顺利中交，8月建成投产。",
                            "type": 1
                        },
                        {
                            "url": "项目一期工程投产后可实现年利税40亿元，将成为地方经济高质量发展的重要增长极，为下游产业链提供优质原料，拉动漳州及周边地区塑料、橡胶、纺织、服装、电子及仪表制造等十几个相关行业、上千亿元产业发展，为地方带来3000多个就业岗位，激活古雷大石化产业链。产品还将辐射至华南地区，对推动扩大两岸经贸往来、增进两岸同胞共同福祉具有重大意义。",
                            "type": 1
                        },
                        {
                            "url": "据悉，项目二期工程目前正在加快推进，预计乙烯产能将提高至140万吨/年，产品总量将增加至420万吨/年。（由中国石化提供）古雷炼化一体化项目顺利投产！（附：全国各大炼化项目进展）",
                            "type": 1
                        },
                        {
                            "url": "8月17日，海峡两岸合资的最大石化项目——福建漳州古雷炼化一体化项目乙烯装置裂解炉投料，历时31小时产出合格乙烯，从项目中交到开车成功只用3个月不到的时间，创下近年中国石化大乙烯中交开车的新纪录。",
                            "type": 1
                        },
                        {
                            "url": "古雷炼化一体化项目是漳州古雷石化工业园区的龙头项目，于2016年1月经福建省发改委核准，由福建炼油化工有限公司和旭腾投资有限公司各占50%股份合资建设。",
                            "type": 1
                        },
                        {
                            "url": "项目建设包括百万吨级乙烯装置，以及10/70万吨/年环氧乙烷/乙二醇装置、60万吨/年苯乙烯装置、35万吨/年聚丙烯装置、30万吨/年乙烯-醋酸乙烯树脂装置、10万吨/年热塑性弹性体装置等9套化工装置及配套公用工程，总投资278亿元，预计年产值260亿元，可拉动当地超1000亿元的下游产业。",
                            "type": 1
                        },
                        {
                            "url": "主要产品既包括苯乙烯、环氧乙烷/乙二醇等基础产品，又涵盖乙烯-醋酸乙烯树脂、聚丙烯、热塑性弹性体等高端材料。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "年产值超600亿!今天投产!",
                    "content": [
                        {
                            "url": "中新社湛江6月16日电 (梁盛 李海)16日，随着炼油常减压装置、轻烃回收装置等6套装置相继投料开车，中国石化中科炼化项目正式投产。",
                            "type": 1
                        },
                        {
                            "url": "中科炼化项目位于广东湛江东海岛，是中国石化在新时代建设的标志性炼化工程，也是中国“一带一路”建设及能源化工发展战略的重要支点。该项目总投资440亿元(人民币，下同)，主要包括1000万吨/年常减压等18套炼油装置，80万吨/年蒸汽裂解等10套化工装置，18万标准立方米/小时煤制氢装置，以及储运、污水处理、码头、铁路专用线、成品油和天然气管道等公用工程及配套设施。",
                            "type": 1
                        },
                        {
                            "url": "据介绍，2018年1月中科项目EPC总承包合同签订，拉开全面建设的序幕，2019年12月全面建成中交。该项目投产后，主要生产国六汽柴油、航空煤油以及高端化工产品，年产值将超过600亿元，可实现利税260多亿元，并拉动塑材、电子化学品、精细化工等中下游产业链的新增投资达2000多亿元，成为湛江地区经济发展的“新引擎”，为粤港澳大湾区经济建设注入强劲动力。",
                            "type": 1
                        },
                        {
                            "url": "更值一提的是，中科炼化项目采用国际先进的生产工艺和控制技术，按照国际最高安全、环保标准进行设计和管理，国内自主研发的先进炼化生产装备技术在项目得到广泛应用，国产化率超过95%，是中国目前生产装备国产化率最高的炼化一体化项目。其中，炼油总流程充分体现了“分子炼油”理念，炼油关键装置从工艺技术到核心设备完全国产化，达到国内领先、国际先进水平。而35万吨/年聚丙烯装置挤压造粒机组的成功研制应用，则打破了外国技术和市场垄断。(完)",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "喜讯  古雷炼化一体化项目4套重要生产装置顺利中交",
                    "content": [
                        {
                            "url": "5月28日上午，古雷炼化一体化项目重点装置中交仪式在项目现场举行，乙烯蒸汽裂解、裂解汽油加氢、芳烃抽提、丁二烯抽提4套重要装置及第一循环水场、罐区（一）2套配套辅助装置顺利实现中间交接，项目建设取得重大进展，标志着古雷炼化一体化项目全面进入投料试车攻坚阶段。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.1.png',
                            "type": 2
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.2.png',
                            "type": 2
                        },
                        {
                            "url": "　中石化集团公司工程建设督导专员宋立群，福建炼化公司总经理、党委副书记刘向东，漳州市委常委、古雷开发区党工委书记沈志平，古雷石化公司领导班子及项目管理部总监团队成员到场参加仪式。",
                            "type": 1
                        },
                        {
                            "url": "　　古雷港经济开发区管委会、古雷石化、古雷石化项目管理部，以及十建公司、四建公司、南京工程公司、中石化工程建设公司、齐鲁监理、洛炼监理等参建单位代表共同见证了这一激动人心的时刻。",
                            "type": 1
                        },
                        {
                            "url": "福建炼化公司副总经理、古雷石化公司总裁、党委书记张西国主持仪式。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.3.png',
                            "type": 2
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.4.png',
                            "type": 2
                        },
                        {
                            "url": "　中交仪式上，参建单位、监理单位、项目管理部、中石化质量监督站及业主方代表分别致辞，并共同签署中交证书。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.5.png',
                            "type": 2
                        },
                        {
                            "url": "　　刘向东代表福建炼化公司致辞。刘向东说，在即将迎来党的百年华诞的重要时刻，在全省上下深入学习贯彻习近平总书记来闽考察重要讲话精神的关键阶段，古雷炼化一体化项目乙烯蒸汽裂解等装置顺利实现中间交接，是项目建设的重要里程碑，对于落实福建省全方位推进高质量发展超越和中国石化构建“一基两翼三新”产业格局、打造世界领先洁净能源化工公司具有重要实践意义。",
                            "type": 1
                        },
                        {
                            "url": "　　装置建设克服新冠肺炎疫情等各种不利影响，最终实现顺利中交，凝结着古雷石化和各参建单位辛勤与智慧，彰显了广大干部员工和建设者良好的精神状态和过硬的工作作风。",
                            "type": 1
                        },
                        {
                            "url": "　　希望古雷石化及各参建单位，继续发扬成绩，主动作为，超常努力，以时不我待的紧迫感责任感，扎实做好开工前各项准备工作，实现安全投料试车一次成功，确保装置开得稳、开得好、开出效益。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.6.png',
                            "type": 2
                        },
                        {
                            "url": "张西国代表古雷石化对关心支持项目建设的各级地方政府、陆台股东方以及各参建单位和全体参建人员，表示了衷心感谢。",
                            "type": 1
                        },
                        {
                            "url": "他指出，古雷炼化一体化项目是迄今为止海峡两岸最大的合资合作石化项目，对加快海峡两岸石化产业深度合作、带动地方经济发展具有重要意义。根据总体统筹控制计划，乙烯裂解装置于7月28日具备投料条件。",
                            "type": 1
                        },
                        {
                            "url": "接下来，他们将全面转入试车开车的关键时期。古雷石化将按照省委省政府、市委市政府以及陆台双方股东关于项目建设的指示要求，以“冲刺7·28，大干100天，实现安全投料试车一次成功”劳动竞赛为抓手，进一步强化现场安全和质量管控，在确保安全可靠、万无一失的前提下，加快完成项目建设收尾，精心优化开车整体统筹，确保项目按照目标计划顺利推进，为区域经济高质量发展贡献应有力量。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_7.7.png',
                            "type": 2
                        },
                        {
                            "url": "　　乙烯蒸汽裂解装置是古雷炼化一体化项目的龙头装置，采用中石化CBL裂解技术及LECT分离技术，主要生产聚合级乙烯和聚合级丙烯等产品，自2019年6月开始土建施工，历时23个月顺利达成中交目标。",
                            "type": 1
                        },
                        {
                            "url": "期间，面对新冠疫情和极端天气带来的严峻考验，古雷石化、项目管理部及全体参建单位按照中石化攻坚创效行动“任务不减、目标不变”的部署要求，本着“一家人、一条心、一个目标、一股劲”的精神，抗疫情、战雨季、斗高温、防台风，克服重重压力和困难，抢时间、拼速度，确保各个关键节点按时到达。",
                            "type": 1
                        },
                        {
                            "url": "创新采用国内首创裂解炉整体模块化施工及转运方案，大胆提出“一个现场、两个工地”的模块化施工管理决策，开辟第二工地为3台乙烯裂解炉提供模块化施工场所，大大地缩短了工期，不仅带动了炼化一体化项目模块化建设全面升级，更为后续大乙烯项目工程建设提供了有力参考。",
                            "type": 1
                        },
                        {
                            "url": "　装置投料试车生产准备工作启动以来，捷报频传，2021年5月16日，K-201裂解气压缩机透平一次试车成功；5月21日，K-501丙烯制冷压缩机透平一次试车成功；5月24日，3#、7#裂解炉点火烘炉一次成功，为全面打赢“安全投料试车一次成功”攻坚战奠定坚实基础。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "中科炼化“云投产",
                    "content": [
                        {
                            "url": " 6月16日，中科（广东）炼化一体化项目在北京、广州和湛江三地以视频连线方式举行“云投产”启动活动。省委书记李希出席活动并宣布项目投产。省长马兴瑞、中国石油化工集团董事长张玉卓、湛江市委书记郑人豪致辞。　",
                            "type": 1
                        },
                        {
                            "url": "马兴瑞代表省委、省政府对项目投产启动表示祝贺。他指出，中科炼化一体化项目一期总投资近440亿元，建设1000万吨/年炼油、80万吨/年乙烯项目及相关辅助配套工程，是国家优化临港重化产业布局的重大项目，也是广东推动制造业高质量发展的代表性项目。项目正式投产启动，是广东与中国石化认真贯彻落实习近平总书记重要讲话和重要指示批示精神，统筹推进疫情防控和经济社会发展，做好“六稳”工作、落实“六保”任务的重要成果，对于增强各方战“疫”信心、深化经贸合作具有重要意义。广东将加强统筹协调，全力为企业的生产发展提供良好环境，发挥好这一龙头项目建设的牵动作用。湛江市要切实担负起主体责任，高标准高效率做好各项服务保障工作，依托中科炼化一体化项目，大力强链延链补链，加快打造成为我省现代化沿海经济带的重要发展极。希望中国石化以项目投产为新起点，加快二期项目规划建设，助推我省加快培育世界级绿色石化产业集群。",
                            "type": 1
                        },
                        {
                            "url": "张玉卓代表中国石化对项目投产启动表示祝贺。他说，中国石化将坚持以习近平新时代中国特色社会主义思想为指导，更加积极主动融入广东“一核一带一区”区域发展新格局，统筹谋划中国石化在粤各项业务发展，努力为广东实现“四个走在全国前列”作出新的更大贡献。我们将全力保证中科炼化一体化项目安稳长满优运行，全力做到发展一方产业，带动一方经济，保护一方环境，富裕一方百姓，为广东省经济社会持续健康发展，为湛江建设广东省省域副中心城市贡献中国石化力量。",
                            "type": 1
                        },
                        {
                            "url": " 郑人豪代表湛江市委、市政府和850多万湛江人民对项目投产启动表示祝贺。他表示，服务好、保障好重点项目建设，是湛江市委、市政府义不容辞的责任。我们必定一如既往、全力以赴支持中科炼化项目生产建设，当好企业“保姆”和“保安”，推动项目不断发展壮大。衷心希望中国石化集团扎根湛江、服务湛江，与我们携手并肩、通力合作，把中科炼化项目做大做优做强，合力打造世界级石化生产基地，为广东构建“一核一带一区”区域发展新格局作出更大贡献。",
                            "type": 1
                        },
                        {
                            "url": "国家有关部委领导王宏、任洪斌、任鸿斌，省领导郑雁雄、覃伟中及中国石油化工集团领导马永生，湛江市领导姜建军、许顺、梁培，区领导罗建君、庞康稳、梁琼荣、孙天翔、林挺等出席活动。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "中科炼化废碱处理装置率",
                    "content": [
                        {
                            "url": "废碱处理装置自2018年4月16日桩基工程开工，于2019年10月30日装置顺利中交，施工周期共历时562天，比总体统筹计划提前2个月中交。",
                            "type": 1
                        },
                        {
                            "url": "废碱处理装置由中石化上海工程有限公司承担总承包，由洛阳石化工程建设集团有限责任公司承担监理，主要参建单位有中石化第十建设有限公司、北京东方新星石化工程股份有限公司、茂名市恒孚石化工程有限公司及广东雷迪通工程技术有限公司。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_9.1.png',
                            "type": 2
                        },
                        {
                            "url": "上午9点38分，上海工程公司承建的中科一体化项目废碱处理装置中交仪式在中科项目现场隆重举行。中科炼化公司、中石化第十建设有限公司、中国石化派驻中科炼化督察组、石油化工工程质量监督总站中科项目监督组、洛阳石化工程建设集团有限责任公司相关领导和上海工程公司总经理助理李真泽、项目主任张宏赓、项目部副主任兼项目经理何兆良、建设部主任汪陆舜、采购部副主任徐亮、质量安全部副主任熊凤鸣、施工经理、设计经理等相关人员和等出席中交仪式。",
                            "type": 1
                        },
                        {
                            "url": "介绍工程建设情况并签署工程中间交接证书",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_9.2.png',
                            "type": 2
                        },
                        {
                            "url": "上海工程公司项目部副主任、项目经理何兆良代表公司介绍工程建设情况并签署废碱处理装置工程中间交接证书。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_9.3.png',
                            "type": 2
                        },
                        {
                            "url": "祝贺废碱处理装置提前中交！",
                            "type": 1
                        },
                        {
                            "url": "在项目实施过程中，上海工程公司充分发挥EPC总承包的优势，在项目准备阶段，就结合项目特点，做好工序策划，将过程前期、设计、采购、土建、安装等建设过程交叉进行、有机结合、合理衔接，以达到缩短工期，保证质量、节省资金的效果。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "中科炼化港口30万吨级原油码头投用",
                    "content": [
                        {
                            "url": "中国石化新闻网报道，5月8日，来自中东的“凯名”号VLCC油轮安全靠泊中国石化中科炼化港口30万吨级原油码头并顺利接卸，标志着中科炼化港口正式建成投用，这是目前国内一次建成规模最大、结构形式和使用功能最多的港口，也是国内最大的石化港口，将形成石化工业“前港后厂”世界领先的生产模式。",
                            "type": 1
                        },
                        {
                            "url": "中科炼化港口与炼化装置的直线距离仅1100米，共有30万吨级原油码头、10万吨级成品油码头等8个泊位，年吞吐能力达3400万吨。",
                            "type": 1
                        },
                        {
                            "url": "其中，10万吨级成品油码头泊位，是目前国内最大的成品油码头，装卸能力达到561万吨/年，将为成品油及化工产品直达国内核心市场以及直接出口国际市场提供便捷，增强企业市场竞争能力。",
                            "type": 1
                        },
                        {
                            "url": "据中国石化新闻网介绍，位于广东湛江东海岛的中科炼化是中国石化在建的最大炼化一体化项目，也是广东省“十三五”重点建设工程。项目一期总投资400多亿元，建设1000万吨/年炼油、80万吨/年乙烯项目及相关辅助配套工程。",
                            "type": 1
                        },
                        {
                            "url": "预计中科炼化项目建成投产后，年产值超过600亿元，将拉动炼化下游产业链发展，为粤港澳大湾区经济建设注入新动力。",
                            "type": 1
                        },
                        {
                            "url": "截至5月8日，中科炼化30套主要生产装置之中已有28套完成中交，项目整体预计7月底全面建成投产。",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "中科炼化首批国六汽柴油出厂",
                    "content": [
                        {
                            "url": "南方日报讯 （记者/顾大炜 刘稳 通讯员/李海 吴金梅）8月30日，中科炼化首批3.38万吨国六汽柴油通过自建码头和湛北输油管线顺利出厂。当天16时，随着湛北（湛江至北海）输油管线东海岛首站机泵开启，2万吨国六柴油经过廉江站源源不断向北海输送。另外1.38万吨92号汽油（国六B）则通过成品油码头装船外运出厂。",
                            "type": 1
                        },
                        {
                            "url": "8月26日，随着240万吨/年S Zorb装置成功切换来自催化装置的催化汽油，标志着炼油第一批开工装置打通全流程。据悉，S Zorb装置采用中国石化独有的S Zorb技术和专有吸附剂，主要处理催化裂化汽油及重整异戊烷，经吸附脱硫后的汽油硫含量小于10ppm，满足国六排放标准对硫含量的严格要求。此次出厂的92号汽油达到国六标准的最高等级（国六B）。",
                            "type": 1
                        },
                        {
                            "url": "中科炼化项目位于广东湛江东海岛，总投资440亿元，主要包括1000万吨/年常减压等18套炼油装置，80万吨/年蒸汽裂解等10套化工装置，18万标准立方米/小时煤制氢装置，以及储运、污水处理、码头、铁路专用线、成品油和天然气管道等公用工程及配套设施。",
                            "type": 1
                        },
                        {
                            "url": "项目采用国际先进的生产工艺和控制技术，国内自主研发的先进炼化生产装备技术在项目得到广泛应用，国产化率超过95％，是目前国内国产化率最高的炼化一体化项目。其中，炼油总流程充分体现了“分子炼油”理念，炼油关键装置从工艺技术到核心设备完全国产化，达到国内领先、国际先进水平。项目环保投资36.88亿元，污染物排放全部优于国家最新标准，达到国际先进水平。",
                            "type": 1
                        },
                        {
                            "url": "项目投产后，年产值将超过600亿元，可实现利税260多亿元，可拉动塑材、电子化学品、精细化工等中下游产业链的新增投资达2000多亿",
                            "type": 1
                        }
                    ]
                },
                {
                    "name": "中科炼化首批化工产品入市",
                    "content": [
                        {
                            "url": "9月4日，280吨聚丙烯树脂产品在中科炼化立体仓库装车出厂，标志着中科炼化首批化工产品入市。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_12.1.png',
                            "type": 2
                        },
                        {
                            "url": "该批树脂产品由35万吨/年聚丙烯装置采用来自上游炼油装置生产的丙烯作为原料生产。该聚丙烯装置采用中石化第三代环管法聚丙烯工艺技术，其核心设备挤出造粒机是国内首台国产最大产能的聚丙烯挤出造粒机，最大产能达60吨/小时，被誉为“国之重器”。其产品主要应用于生产编织袋、地毯、纤维制品等。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_12.2.png',
                            "type": 2
                        },
                        {
                            "url": "   目前，中科炼化炼油第一批开工装置已打通全流程，8月30日，首批3.38万吨国六汽柴油通过自建码头和湛北输油管线顺利出厂，成为我国华南、西南等市场清洁能源供应的新成员。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_12.3.png',
                            "type": 2
                        },
                        {
                            "url": "   据悉，中科炼化项目投产后，年产值将超过600亿元，可实现利税260多亿元，可拉动塑材、电子化学品、精细化工等中下游产业链的新增投资达2000多亿元，并带来大量就业岗位，成为湛江地区经济发展的新引擎，为粤港澳大湾区经济建设注入强劲动力。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_12.4.png',
                            "type": 2
                        }
                    ]
                },
                {
                    "name": "中科项目建设投产表彰大会",
                    "content": [
                        {
                            "url": "12月3日下午，公司召开中科项目建设投产表彰大会，全面总结项目自建设取得的成绩和经验，表彰在项目建设及开工过程中涌现的先进集体和个人，进一步巩固成绩、弘扬正气、发扬优势，凝聚全体中科人的智慧和力量，朝着建设安全绿色世界领先企业的目标奋勇前进。",
                            "type": 1
                        },
                        {
                            "url": "公司执行董事、党委书记吴惜伟出席会议并讲话，公司总经理曾松做项目建设开工情况报告，公司党委副书记方云主持会议并宣读表彰决定，公司和项目部领导郭新、朱华周、张悦、罗志荣、邓瀚深、吕建成、庄晓峰出席大会。",
                            "type": 1
                        },
                        {
                            "url": "我公司参建的项目管理团队荣获建设集体一等功。",
                            "type": 1
                        },
                        {
                            "url": 'http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_13.png',
                            "type": 2
                        }
                    ]
                }
            ],
            list: [
                { name: '风机一响，黄金万两！中科炼化一重量级装置成功启用' },
                { name: '攻坚场景美如画 最美一线石化人' },
            ],
            listIndex: 0,
            info: {}
        };
    },
    created() {

        let i = this.$route.query.i
        this.info = this.projectList[i]
        console.log(this.info)
    },
    mounted() {
        this.$nextTick(() => {
            this.$wow.init()
        })
    },

    methods: {
        scrollToTop() {
            let that = this;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            that.scrollTop = scrollTop;
            this.welcomeshow = true
            this.welcomeshow1 = true
            this.welcomeshow2 = true
            this.welcomeshow3 = true
            this.delay = '0s'
            if (that.scrollTop > 50) {
                that.btnFlag = true;
            } else {
                that.btnFlag = false;
            }
        },
        infoFun(index) {
            this.$router.push({ path: '/information/index', query: { i: index } })
        },
    },
};
</script>


<style lang="less" scoped>
.pc_max_header {
    padding-top: 6.25rem;
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    .jinbi_box {
        height: 1.7813rem;

        .jinbi_name {
            margin-right: .5313rem;
        }

        .jinbi_icon {
            width: .2188rem;
            margin-right: .5313rem;
        }
    }

    .max_title {
        // mar
        color: #000000;
    }

    .logo_box {
        width: 9.375rem;
        // height: 75;
        margin-top: .8125rem;
        background: linear-gradient(180deg, #F2F2F2 0%, #F2F2F2 53%, #F9F9F9 100%);
        border-radius: .3125rem;
        padding: .3125rem 0;

        .min_logo_icon {
            width: 1.5313rem;
        }

        .xian {
            display: inline-block;
            height: 1.7188rem;
            border-left: .0313rem solid #BDBDBD;
            margin: 0 1.0938rem;
        }

        .time_box {
            display: flex;
            flex-direction: column;

            .time_day {
                color: #000000;
            }
        }
    }
}

.text_container {
    width: 28.8438rem;
    padding-bottom: 1.6875rem;
    border-bottom: 1px solid #BDBDBD;

    .text_p {
        display: inline-block;
        margin-bottom: 1.875rem;
        text-indent: 2em;
        // letter-spacing: .1563rem;
        line-height: 1.875rem;
    }

    .project_image {
        width: 100%;
        // min-height: 6.0241rem;
        margin-bottom: 1.875rem;
    }
}

.project_item {
    margin-bottom: 1.75rem;
    border: 1px solid #D6D6D6;
    border-radius: .2188rem;
    margin-right: 2.5938rem;
    padding-right: 0;
    width: calc(50% - 1.4938rem);
    overflow: hidden;

    &:nth-child(2) {
        margin-right: 0;
    }

    &:hover {
        .image_logo {
            transform: scale(1.2);
            transition: all .5s;
        }
    }

    .image_logo {
        width: 100%;
        height: 5.6875rem;
        object-fit: cover;
        border-radius: .2188rem .2188rem 0px 0px;
        background: #D9D9D9;
    }

    .type_name {
        margin-top: 1.0938rem;
        margin-bottom: .625rem;
        margin-left: 1.0313rem;
    }

    .info {
        margin-left: 1.0313rem;
        // width: 9.125rem;
        padding-right: 2.1875rem;

        .project_name {
            font-size: .8125rem;
            height: 1.875rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            /* 设置显示的行数 */

        }
    }

    .ljie_box {
        display: flex;
        align-items: center;
        margin-left: 1.0313rem;
        margin-top: 2.625rem;
        margin-bottom: .8438rem;

        .farrow_icon {
            width: 1rem;
            height: 1.0313rem;
            margin-right: .4688rem;
        }

        .ljie {
            color: #2F80ED;
        }
    }
}

.row>*:nth-child(1n) {
    padding-left: 0 !important;
}

.row>*:nth-child(3n) {
    padding-right: 0 !important;
}

.footer_box {
    width: 28.8438rem;

    .footer_title {
        display: inline-block;
        margin-bottom: 1.25rem;
    }

}

@media (min-width: 200px) and (max-width: 768px) {
    .max_title{
        margin: 0 1.5625rem;
    }
    .h5_max_header {
        min-height: 100% !important;
    }

    .section {
        padding-top: 2rem !important;
    }

    .text_container,
    .footer_box {
        width: auto !important;
    }

    .logo_box {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text_container {

        .text_p {
            display: inline-block;
            margin-bottom: 1.875rem;
            text-indent: 2em;
        }

        .project_image {
            width: 100%;
            margin-bottom: 1.875rem;
        }
    }

    .project_item {
        width: 83%;
        margin: 0 auto;
        margin-bottom: 1.25rem;

        &:nth-child(2n) {
            margin-left: 0;
            margin: 0 auto;
            margin-bottom: 1.25rem;
        }
    }
}
</style>