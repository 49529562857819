<template>
  <nav
    id="navbarHeader"
    :class="
      btnFlag
        ? 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center nav-sticky'
        : 'navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark align-items-center'
    "
  >
    <div class="container">
      <div
        @click="routerBtn('/home')"
        class="logo mr-3 d-flex justify-content-center align-items-center"
        style="margin-right: 5.1563rem"
      >
        <img
          src="http://si54wj5yl.hn-bkt.clouddn.com/images/footer/logo.png"
          style="width: 2.4375rem"
          class="img-fluid"
          alt="..."
        />
        <span class="T3_Bold ml_18 max_title">海南海派克</span>
      </div>
      <button
        class="navbar-toggler"
        @click="show = !show"
        type="button"
        data-toggle="collapse"
        data-target="#navbarHeader"
        aria-controls="navbarHeader"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <img
          v-show="!btnFlag"
          src="http://si54wj5yl.hn-bkt.clouddn.com/images/daohanlan.svg"
          style="width: 1rem"
          alt=""
        />
        <img
          v-show="btnFlag"
          src="http://si54wj5yl.hn-bkt.clouddn.com/images/daohanlans.svg"
          style="width: 1rem"
          alt=""
        />
      </button>
      <!-- <i class="icon-align-left"></i> -->

      <div
        class="collapse navbar-collapse"
        :class="show ? 'show' : ''"
        id="navbarCollapse"
      >
        <ul class="navbar-nav navbar-center" id="mySidenav">
          <li
            class="nav-item"
            :class="btnFlag ? 'nav-items' : ''"
            @click="routerBtn('/home')"
          >
            <a>
              <span class="T3_Bold">首页</span>
            </a>
          </li>
          <li class="nav-item" :class="btnFlag ? 'nav-items' : ''">
            <b-nav-item-dropdown text="关于我们" class="111">
              <b-dropdown-item @click="goAssignBlock('block1', 30, '/aboutus')"
                >公司简介</b-dropdown-item
              >
              <b-dropdown-item @click="goAssignBlock('block2', 30, '/aboutus')"
                >服务内容</b-dropdown-item
              >
              <b-dropdown-item @click="goAssignBlock('block3', 30, '/aboutus')"
                >公司理念</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </li>
          <li class="nav-item" :class="btnFlag ? 'nav-items' : ''">
            <b-nav-item-dropdown text="公司业绩">
              <b-dropdown-item
                @click="
                  goAssignBlock('block1', 30, '/achievement', { topindex: 1 })
                "
                >项目管理</b-dropdown-item
              >
              <b-dropdown-item
                @click="
                  goAssignBlock('block1', 30, '/achievement', { topindex: 2 })
                "
                >设计业务</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </li>
          <li class="nav-item" :class="btnFlag ? 'nav-items' : ''">
            <b-nav-item-dropdown text="企业动态">
              <b-dropdown-item @click="goAssignBlock('block1', 30, '/dynamic')"
                >新闻中心</b-dropdown-item
              >
            </b-nav-item-dropdown>
          </li>
          <li
            class="nav-item"
            :class="btnFlag ? 'nav-items' : ''"
            @click="routerBtn('/contact')"
          >
            <a>
              <span class="T3_Bold">联系我们</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
// import $ from 'jquery'
export default {
  name: "GlobalHeader",
  data() {
    return {
      btnFlag: false,

      show: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop, true);
  },
  methods: {
    /**
     * 滚动到指定位置
     */
    goAssignBlock(el, speed, url, query) {
      let path = this.$route.path;

      if (path.indexOf("url") == -1) {
        this.$router.push({ path: url, query: query });
      }

      this.$emit("goAssignBlock", el, speed, url);
    },
    scrollToTop() {
      this.show = false;
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
    routerBtn(url) {
      // console.log(url)
      this.$router.push(url);
    },
  },
};
</script>

<style scoped>
::v-deep .nav-item .dropdown-menu {
  background: rgba(25, 21, 21, 0.42);
}

::v-deep .nav-items .dropdown-menu {
  background: #fff;
}

.navbar-custom .navbar-nav li a {
  padding: 0 !important;
}

/* ::v-deep .navbar-custom .dropdown-menu{
    background: rgba(25, 21, 21, 0.42) ;
} */
.navbar-center,
.container {
  display: flex;
  align-items: center;
}

.navbar > .container {
  display: flex;
  align-items: center;
}

@media (min-width: 200px) and (max-width: 768px) {
  .max_title {
    font-size: 0.9639rem;
  }
}
</style>
