<template>
  <div>
    <section class="section p0" style="padding-bottom: 0">
      <div class="container_max">
        <div class="row justify-content-center">
          <div class="text-center col-lg-12 p0">
            <div
              id="carouselExampleInterval"
              class="carousel carousel-dark slide"
            >
              <video
                autoplay
                muted
                loop
                playsInline="{true}"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                x5-playsinline="true"
                x5-video-orientation="portraint"
                style="width: 100%; height: 100%; object-fit: cover"
                src="https://mooxun.oss-cn-hangzhou.aliyuncs.com/%EF%BC%AD%EF%BC%B0%EF%BC%94/WeChat_20240808095302.mp4"
              ></video>

              <div
                class="
                  text_finx
                  d-flex
                  flex-column
                  full_screen
                  justify-content-center
                  wow
                "
              >
                <h1 class="T1_Bold mb-23 text-B7 max_title_home text-center">
                  专注能源化工工程，提供国际化设计和项目管理服务，致力于全产业链解决方案
                </h1>

                <div class="T5_Regular max_title_home text-center text-C2">
                  项目管理 技术服务 工程设计 课程培训
                </div>

                <div
                  class="T5_Regular text-B6 learn_more_btn"
                  @click="goAssignBlock('blockhome', 30)"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>

              <div class="pagination_finx">
                <div class="swiper-paginationS"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 服务内容 -->
    <section
      class="section section_fuwu"
      ref="blockhome"
      style="padding-top: 0; margin-top: 1.75rem; padding-bottom: 5rem"
    >
      <!-- // pc端 -->
      <div class="container container_fuwu">
        <header class="row">
          <div
            class="col-lg-4 wow animate__backInUp"
            style="margin-top: 0.625rem"
          >
            <h1 class="T0_Bold text-B0">服务内容</h1>
            <p class="T5_Regular text-B2">
              石油化工专家、技术骨干及专业技术管理人员团队
            </p>
          </div>
          <div class="col-lg-8 p0">
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/SERVICE.png"
              class="img-fluid"
              alt="..."
            />
          </div>
        </header>

        <div
          class="d-flex justify-content-between flex-wrap align-items-center"
        >
          <div
            data-wow-delay=".5s"
            class="d-flex flex-column bg_fff col-lg-2 col-12 service_item"
            v-for="(item, index) in serviceList"
            :key="index"
          >
            <img
              :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/f${item.icon}.png`"
              :class="`i_hover${item.icon}`"
              :style="`width:${item.iW}`"
              class="img-fluid mb-26"
              alt="..."
            />

            <h4 class="T4_Medium text-B0 mb-26 f_hover">{{ item.title }}</h4>

            <span class="T5_Regular text-B2 mb-77 min_h f2_hover">
              {{ item.text }}
            </span>

            <footer
              class="d-flex align-items-center f_hover"
              @click="routerBtn('/aboutus')"
            >
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/farrow.png"
                style="width: 1rem"
                class="img-fluid mr-16 farrow_icon"
                alt="..."
              />
              <span class="T5_Bold text-C1 f_hover">了解更多</span>
            </footer>

            <div class="line"></div>
          </div>
        </div>
      </div>
      <!-- h5端 -->
      <div class="container container_h5_fuwu">
        <header class="d-flex justify-content-between fuwu_header">
          <div
            class="col-lg-4 col-5 wow animate__backInUp"
            style="margin-top: 0.625rem"
          >
            <h1 class="T0_Bold text-B0 fuw_title">服务内容</h1>
            <p class="T5_Regular text-B2 fuw_title_tips">
              石油化工专家、技术骨干及专业技术管理人员团队
            </p>
          </div>
          <div class="p0">
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/SERVICE.png"
              class="img-fluid SERVICE_logo"
              alt="..."
            />
          </div>
        </header>

        <div
          data-wow-delay=".5s"
          class="
            d-flex
            justify-content-between
            flex-wrap
            align-items-center
            wow
            animate__fadeInUp
          "
        >
          <swiper class="swiperS w-100 h-100" :options="h5SwiperOption">
            <swiper-slide
              v-for="item in serviceList"
              :key="item"
              class="swiper-slide"
            >
              <div class="d-flex flex-column bg_fff service_item">
                <img
                  :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/f${item.icon}.png`"
                  :class="`i_hover${item.icon}`"
                  :style="`width:${item.iW}`"
                  class="img-fluid mb-26"
                  alt="..."
                />

                <h4 class="T1_Medium text-B0 mb-26 f_hover service_item_title">
                  {{ item.title }}
                </h4>

                <span
                  class="
                    T5_Regular
                    text-B2
                    mb-30
                    min_h
                    f2_hover
                    service_item_tips
                  "
                >
                  {{ item.text }}
                </span>
                <footer
                  class="d-flex align-items-center f_hover"
                  @click="routerBtn('/aboutus')"
                >
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/farrow.png"
                    style="width: 1rem"
                    class="img-fluid mr-16 farrow_icon"
                    alt="..."
                  />
                  <span class="T5_Bold text-C1 f_hover ljie_tips"
                    >了解更多</span
                  >
                </footer>
                <div class="line"></div>
              </div>
            </swiper-slide>
          </swiper>

          <div class="pagination_finx">
            <div class="swiper-paginationh5"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- 项目案例 -->
    <section
      class="section case_section"
      style="padding-top: 0; padding-bottom: 5rem"
    >
      <!-- pc端 -->
      <div class="container case_container_pc">
        <header class="row">
          <div
            class="col-lg-3 p0 text_box wow animate__fadeInRight"
            style="margin-top: 0.625rem"
          >
            <h1 class="T0_Bold text-B0 text-end">项目案例</h1>
            <p class="T4_Regular text-B2 text-end" style="margin: 0">
              70+成功石油化工相关项目
            </p>
            <!-- <div class="gengduoanli"></div> -->
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/gengduo_icon.png"
              @click="routerBtn('/achievement', { topindex: 1 })"
              class="gengduo_icon"
              alt=""
            />
          </div>

          <div class="col-lg-9 p0">
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/PROJECT.png"
              class="img-fluid PROJECT_icon"
              alt="..."
            />
          </div>
        </header>
        <!-- pc端 -->
        <div class="d-flex flex-wrap justify-content-between">
          <div
            class="d-flex flex-column bg_fff col-lg-2 col-12 case_item"
            @click="caseIndx = index"
            :class="index == caseIndx ? 'active' : ''"
            v-for="(item, index) in caseList"
            :key="index"
          >
            <img
              :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/c_logo.png`"
              style="width: 1.4375rem"
              class="img-fluid mb-22"
              alt="..."
            />

            <h4 class="T5_Bold text-B0 mb-15 f_hover">项目名称</h4>

            <h3 class="T4_Bold text-B0 mb-15 f_hover">{{ item.title }}</h3>

            <span class="T6_Regular text-B2 mb-28 min_h f2_hover">
              {{ item.time }}
            </span>

            <footer v-show="caseIndx == index" class="f_hover">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medals.png"
                style="width: 2.0938rem"
                class="img-fluid mr-16 medal_icon"
                alt="..."
              />
            </footer>
            <footer v-show="caseIndx != index" class="f_hover">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medal.png"
                style="width: 2.0938rem"
                class="img-fluid mr-16 medal_icon"
                alt="..."
              />
            </footer>

            <transition name="close">
              <div
                class="T5_Regular text-B6 case_learn_more_btn"
                @click="routerBtn('/achievement')"
                v-show="index == caseIndx"
              >
                了解更多
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                  style="width: 0.75rem"
                  class="img-fluid"
                  alt="..."
                />
              </div>
            </transition>
          </div>
        </div>
        <!-- 背景照片 -->
        <div class="d-flex justify-content-between">
          <div
            class="d-flex flex-column bg_fff col-lg-2 case_bj_item"
            @click="caseIndx = index"
            :class="index == caseIndx ? 'active' : ''"
            :style="`left:${19 * index}%;`"
            v-for="(item, index) in caseList"
            :key="index"
          ></div>
        </div>
      </div>

      <!-- h5端 -->
      <div class="container case_container_h5">
        <header class="row_header d-flex justify-content-between">
          <div
            class="col-lg-3 col-5 text_box wow animate__backInUp"
            style="margin-top: 0.625rem"
          >
            <h1 class="T0_Bold text-B0 case_title">项目案例</h1>
            <p
              class="T5_Regular text-B2 case_title_tips"
              style="margin-bottom: 0"
            >
              70+成功石油化工相关项目
            </p>
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/gengduo_icon.png"
              @click="routerBtn('/achievement', { topindex: 1 })"
              class="gengduo_icon"
              alt=""
            />
          </div>
          <div class="col-lg-9 p0">
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/PROJECT.png"
              class="img-fluid PROJECT_icon"
              alt="..."
            />
          </div>
        </header>
        <div class="d-flex flex-wrap justify-content-center">
          <div
            class="
              col-12
              d-flex
              flex-wrap
              justify-content-center
              case_item_max_bj
              wow
              animate__backInUp
            "
            v-for="(item, index) in caseList"
            :key="index"
            :class="index == caseIndx ? 'active' : ''"
            @click="caseIndx = index"
          >
            <div
              class="col-12 case_item d-flex flex-column bg_fff"
              :class="index == caseIndx ? 'active' : ''"
            >
              <img
                :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/c_logo.png`"
                style="width: 1.4375rem"
                class="img-fluid c_logo mb-22"
                alt="..."
              />

              <span class="T5_Regular text-B0 f_hover xiangmumc">项目名称</span>

              <h3 class="T4_Bold text-B0 f_hover biaotixiangmu">
                {{ item.title }}
              </h3>

              <span class="T6_Regular text-B2 mb-12 min_h f2_hover">
                {{ item.time }}
              </span>

              <div
                class="d-flex align-items-center"
                style="margin-top: 1.3253rem"
              >
                <footer v-show="caseIndx == index" class="f_hover">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medals.png"
                    style="width: 2.0938rem"
                    class="img-fluid mr-16 medal_icon"
                    alt="..."
                  />
                </footer>
                <footer v-show="caseIndx != index" class="f_hover">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medal.png"
                    style="width: 2.0938rem"
                    class="img-fluid mr-16 medal_icon"
                    alt="..."
                  />
                </footer>

                <transition name="close">
                  <div
                    class="T7_Regular text-B6 case_learn_more_btn"
                    @click="routerBtn('/achievement')"
                    v-show="index == caseIndx"
                  >
                    了解更多
                    <img
                      src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                      style="width: 0.75rem"
                      class="img-fluid arrow_left_icon"
                      alt="..."
                    />
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section bg_F7F7F7" style="padding: 1.3125rem 0">
      <div class="container">
        <div class="d-flex flex-column">
          <div
            class="
              w-100
              text-center
              d-flex
              justify-content-center
              align-items-center
            "
            style="margin-bottom: 1.3438rem"
          >
            <div class="swiper-button-nexts" slot="button-next"></div>
            <h2 class="T2_Bold text-B0" style="margin: 0 1.9375rem">
              合作客户
            </h2>
            <div class="swiper-button-prevs" slot="button-prev"></div>
          </div>
          <div class="col-lg-12 d-flex justify-content-content">
            <swiper class="swiper" :options="swiperOption">
              <swiper-slide v-for="item in 9" :key="item" class="swiper-slideb">
                <div class="img_box">
                  <img
                    :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/cooperate/c${item}.png`"
                    class="img-cooperate"
                    alt="..."
                  />
                </div>
                <!-- <img src="" alt=""> -->
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
    </section>

    <!-- 新闻资讯 -->
    <section
      class="section new_section"
      style="padding-top: 3.375rem; padding-bottom: 3.625rem"
    >
      <!-- // pc端 -->
      <div class="container news_box news_box_pc">
        <div class="row">
          <div class="col-lg-12 col-md-4">
            <header class="d-flex justify-content-start align-items-center">
              <h2 class="T2_Bold text-B0" style="margin-right: 0.9375rem">
                新闻咨询
              </h2>

              <div
                class="news_nav_item T4_Bold text-B2 bg_F2F2F2"
                @click="newsIndex = 0"
                :class="newsIndex == 0 ? 'active' : ''"
              >
                项目资讯
                <img
                  src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/active_icon.png"
                  v-show="newsIndex == 0"
                  style="width: 0.8438rem"
                  class="img-fluid active_icon_fix"
                  alt="..."
                />
              </div>
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/NEWS.png"
                class="img-fluid NEWS_icon"
                alt="..."
              />
            </header>
          </div>

          <div class="container">
            <div class="col-lg-12 col-12 news_list row">
              <div class="col-md-8 col-8" style="padding: 0">
                <div
                  class="max_news_item_box min_news_item_box"
                  style="padding: 0"
                >
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/n1.png"
                    class="img-fluid w-100 h-100"
                    alt="..."
                  />
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div class="min_news_item_box bg_F7F7F7" @click="infoFun(0)">
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1">
                    风机一响，黄金万两！中科炼化一重量级装置成功启用
                  </h4>
                  <span class="T5_Regular text-B2">2021-09-16</span>
                </div>
              </div>
              <div
                class="col-md-8 col-8"
                style="padding: 0"
                @click="infoFun(6)"
              >
                <div class="max_news_item_box news_item_box bg_F7F7F7">
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1">
                    喜讯 古雷炼化一体化项目4套重要生产装置顺利中交
                  </h4>
                  <span class="T5_Regular text-B2">2021-05-29</span>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div class="min_news_item_box bg_F7F7F7" @click="infoFun(2)">
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1">
                    海派克参管中科EVA装置中交
                  </h4>
                  <span class="T5_Regular text-B2">2021-08-22</span>
                </div>
              </div>

              <div class="col-md-8 col-8" style="padding: 0">
                <div
                  class="max_news_item_box news_item_box bg_F7F7F7"
                  @click="infoFun(7)"
                >
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1">
                    中科炼化“云投产
                  </h4>
                  <span class="T5_Regular text-B2">2021-05-29</span>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div class="min_news_item_box bg_F7F7F7" style="padding: 0">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/n2.png"
                    class="img-fluid w-100 h-100"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center">
            <div class="learn_more_btns" @click="routerBtn('/dynamic')">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/xiayiye.png"
                style="width: 1.0938rem"
                class="img-fluid"
                alt="..."
              />

              <span class="text-B0 T5_Regular">查看更多</span>
            </div>
          </div>
        </div>
      </div>

      <!-- <h5></h5> -->
      <div class="container news_box news_box_h5">
        <div class="">
          <div class="col-lg-12 col-md-4">
            <header
              class="
                d-flex
                justify-content-between
                align-items-center
                new_header
              "
            >
              <h2 class="T2_Bold text-B0 new_title">新闻咨询</h2>
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/NEWS.png"
                class="img-fluid NEWS_icon"
                alt="..."
              />
            </header>
          </div>

          <div class="container">
            <div
              class="
                news_list
                d-flex
                flex-row
                justify-content-between
                align-items-center
                flex-wrap
              "
            >
              <div class="col-md-8 col-8" style="padding: 0">
                <div
                  class="max_news_item_box min_news_item_box n1_max_img"
                  style="padding: 0"
                >
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/n1.png"
                    class="img-fluid img-thumbnail h-100"
                    alt="..."
                  />
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div class="min_news_item_box bg_F7F7F7" @click="infoFun(0)">
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1 new_name">
                    风机一响，黄金万两！中科炼化一重量级装置成功启用
                  </h4>
                  <span class="T5_Regular text-B2 new_time">2021-09-16</span>
                </div>
              </div>
              <div class="col-md-8 col-8" style="padding: 0">
                <div
                  class="max_news_item_box news_item_box bg_F7F7F7"
                  @click="infoFun(6)"
                >
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1 new_name">
                    喜讯 古雷炼化一体化项目4套重要生产装置顺利中交
                  </h4>
                  <span class="T5_Regular text-B2 new_time">2021-05-29</span>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div class="min_news_item_box bg_F7F7F7" @click="infoFun(2)">
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1 new_name">
                    海派克参管中科EVA装置中交
                  </h4>
                  <span class="T5_Regular text-B2 new_time">2021-08-22</span>
                </div>
              </div>

              <div class="col-md-8 col-8" style="padding: 0">
                <div
                  class="max_news_item_box news_item_box bg_F7F7F7"
                  @click="infoFun(7)"
                >
                  <h4 class="T2_Bold font-weight-normal mb-12 text-B1 new_name">
                    中科炼化“云投产
                  </h4>
                  <span class="T5_Regular text-B2 new_time">2021-05-29</span>
                </div>
              </div>
              <div class="col-md-4 col-4">
                <div
                  class="min_news_item_box bg_F7F7F7 n1_max_img"
                  style="padding: 0"
                >
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/n2.png"
                    class="img-thumbnail w-100 h-100"
                    alt="..."
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <div class="learn_more_btns" @click="routerBtn('/dynamic')">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/xiayiye.png"
                style="width: 1.0938rem"
                class="img-fluid"
                alt="..."
              />

              <span class="text-B0 T5_Regular">查看更多</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import goldline from "@/assets/images/goldline.png";
// import aven from "@/assets/images/aven.png";
// import kanba from "@/assets/images/kanba.png";
// import kyan from "@/assets/images/kyan.png";
// import spotify from "@/assets/images/spotify.png";
// import Surface from "@/assets/images/Surface.png";
// import dashboard from "@/assets/images/Dashboard.png";
// import Image39 from "@/assets/images/Image-39.png";

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Home",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {

      height: "100%",
      serviceList: [
        {
          icon: "1",
          iW: "1.0938rem",
          title: "项目管理",
          text: "百名专业人才，设计、采购、施工、控制、质量等领域，提供专业化项目管理服务。",
        },
        {
          icon: "2",
          iW: "1.75rem",
          title: "技术服务",
          text: "立足石油化工工程建设领域，为客户提供技术转让、技术咨询等服务。",
        },
        {
          icon: "3",
          iW: "1.75rem",
          title: "工程设计",
          text: "设计人员，资深高级工程师，具备独立承担化工、石油储运和炼油工程的能力。",
        },
        {
          icon: "4",
          iW: "1.25rem",
          title: "专业资质证书",
          text: "化工石化医药行业，化工工程、石油及化工产品储运、炼油工程，专业乙级工程设计资质证书。",
        },
        {
          icon: "5",
          iW: "1.5625rem",
          title: "课程培训",
          text: "针对计划控制，费用控制，HSE管理，质量管理培训。",
        },
      ],

      caseList: [
        {
          title: "中科合资广东炼化一体化项目",
          time: "2018.07-2021.12",
        },
        {
          title: "天津石化炼油改造项目",
          time: "2018.07-2021.06",
        },
        {
          title: "恒逸文莱PMB石油化工项目",
          time: "2018.01-2019.05",
        },
        {
          title: "古雷石化炼化一体化项目",
          time: "2019.02-2023.03",
        },
        {
          title: "镇海炼化项目",
          time: "2020.04-当前",
        },
      ],
      maxSwiperOption: {
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-paginationS",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + "</span>";
          },
        },
      },
      h5SwiperOption: {
        // autoplay: {
        //   delay: 3000,
        //   disableOnInteraction: false
        // },
        slidesPerView: "auto",
        spaceBetween: 0,
        pagination: {
          el: ".swiper-paginationh5",
          clickable: true,
          renderBullet: function (index, className) {
            return '<span class="' + className + '">' + "</span>";
          },
        },
      },
      caseIndx: 0,
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 30,

        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-nexts",
          prevEl: ".swiper-button-prevs",
        },
        // breakpoints: {

        //   1024: {
        //     slidesPerView: 4,
        //     spaceBetween: 40
        //   },
        //   768: {
        //     slidesPerView: 3,
        //     spaceBetween: 30
        //   },
        //   640: {
        //     slidesPerView: 2,
        //     spaceBetween: 20
        //   },
        //   320: {
        //     slidesPerView: 1,
        //     spaceBetween: 10
        //   }
        // }
      },

      newsIndex: 0,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  mounted() {
    window.addEventListener("resize", this.changeHeight);
    this.changeHeight();
    this.$nextTick(() => {
      this.$wow.init();
    });
  },

  methods: {
    changeHeight() {
      // this.height = `${this.$refs.dashboardImg.offsetHeight + 80}px`;
    },
    routerBtn(url) {
      this.$router.push(url);
    },
    infoFun(index) {
      this.$router.push({ path: "/information/index", query: { i: index } });
    },
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight; //浏览器窗口高度

      // let h = this.$refs.view.$children[0].$refs[el].offsetHeight; //模块内容高度
      let t = this.$refs[el].offsetTop; //模块相对于内容顶部的距离

      let top = t - (windowH - t) / 2; //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; //滚动条距离顶部高度
      let currentTop = scrollTop; //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId;
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向下滚动
          currentTop += speed;
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed);
            requestId = window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
          //向上滚动
          currentTop -= speed;
        }
      }
      window.requestAnimationFrame(step);
    },
  },
};
</script>

<style lang="less" scoped>
#carouselExampleInterval {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .text_finx {
    width: 12.4063rem;
    min-height: 480px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 99;
  }

  .pagination_finx {
    position: absolute;
    bottom: 1.5625rem;
    z-index: 99;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .max_image {
    // position: relative;
    object-fit: cover;
    // z-index: 1;
  }

  .swiper-slide {
    &::before {
      content: " ";
      position: absolute;
      display: inline-block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(17, 36, 52, 0.63);
      // background-color: #000000;
      // z-index: 999;
    }
  }
}

::v-deep .swiper-pagination-bullet {
  width: 0.3125rem !important;
  height: 0.3125rem !important;
  margin-left: 0.875rem;
  background: #f2f2f2;
  opacity: 1;
}

::v-deep .swiper-pagination-bullet-active {
  background: #2f80ed;
}

.container_max {
  height: 100vh;

  .full_screen,
  .carousel,
  .carousel-inner,
  .carousel-item {
    height: 100vh;
  }
}

.learn_more_btn {
  width: 3.5313rem;
  height: 1.2188rem;
  line-height: 1.2188rem;
  text-align: center;
  border-radius: 0.8438rem;
  border: 0.0313rem solid #f2f2f2;
  padding-left: 0.3125rem;
  margin: 0 auto;
  margin-top: 1rem;

  .img-fluid {
    transition: all 0.2s ease;
    transform: rotate(0deg);
  }

  .arrow_icon {
    width: 0.75rem;
  }
}

.learn_more_btns {
  width: 4.3188rem;
  height: 1.4813rem;
  // line-height: 57px;
  text-align: center;
  border-radius: 2.2813rem;
  border: 0.0313rem solid #000000;
  // padding-left: 10px;
  margin-top: 1.0625rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.case_learn_more_btn {
  width: 4.3125rem;
  height: 1.2188rem;
  line-height: 1.2188rem;
  text-align: center;
  border-radius: 0.0938rem;
  border: 0.0313rem solid #f2f2f2;
  padding-left: 0.3125rem;
  margin-top: 1.375rem;
  margin-bottom: 0.5313rem;

  transition: all 0.2s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .img-fluid {
    transition: all 0.2s ease;
    transform: rotate(0deg);
  }
}

.case_learn_more_btn:hover {
  background-color: #fff;
  color: #2f80ed !important;

  .img-fluid {
    transition: all 0.2s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/arrowl.png");
  }
}

.learn_more_btn:hover {
  background-color: #2f80ed;
  color: #ffff;

  .img-fluid {
    transition: all 0.2s ease;
    transform: rotate(90deg);
  }
}

.service_item {
  margin-top: 0.5313rem;
  padding: 0.7188rem 0.4688rem 1.125rem 0.7188rem;
  box-shadow: 0px 0.0313rem 0.0938rem 0px rgba(0, 0, 0, 0.25);
  position: relative;
  height: 11.875rem;

  .min_h {
    min-height: 3rem;
  }
}

.service_item:hover {
  background-color: #2f80ed;
  transition: all 0.3s;

  &::before {
    background-color: #fff !important;
  }

  .f_hover {
    color: #ffff !important;
  }

  .f2_hover {
    color: #f2f2f2 !important;
  }

  .i_hover1 {
    transition: all 0.3s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/f1s.png");
  }

  .i_hover2 {
    transition: all 0.3s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/f2s.png");
  }

  .i_hover3 {
    transition: all 0.3s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/f3s.png");
  }

  .i_hover4 {
    transition: all 0.3s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/f4s.png");
  }

  .i_hover5 {
    transition: all 0.3s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/f5s.png");
  }

  .farrow_icon {
    transition: all 0.3s ease;
    // content: url('http://si54wj5yl.hn-bkt.clouddn.com/images/home/farrowS.png');
  }
}

.service_item::before {
  width: 100%;
  content: " ";
  height: 0.1875rem;
  background-color: #2f80ed;
  position: absolute;
  left: 0;
  bottom: 0;
}

.case_section {
  background: #f1f1f1;
  padding-top: 6.7813rem !important;
  min-height: 26rem;

  .text_box {
    position: absolute;
    top: 0.9375rem;
    right: 2.0313rem;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .gengduo_icon {
      width: 3.0313rem;
      margin-top: 0.8438rem;
    }
  }

  .PROJECT_icon {
    position: absolute;
    right: 0;
    top: 0.9375rem;
    width: 37.5rem;
    z-index: 10;
  }

  .col-lg-2 {
    // width: 18.88888% ;
  }

  .case_item {
    position: relative;
    width: 9.25rem;
    z-index: 20;
    margin-top: 0.5313rem;
    padding: 0.5313rem 1.375rem 2.5313rem 0.875rem;
    box-shadow: 0px 0.0313rem 0.0938rem 0px rgba(0, 0, 0, 0.25);
    height: 10.875rem;
    transition: 0.5s height;

    // z-index: 999;
    &::before {
      content: "";
      width: 100%;
      height: 0.1875rem;
      background-color: #2f80ed;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .case_item.active {
    height: 14.6875rem;
    transition: 0.8s height;
    background-color: #2f80ed !important;

    &::before {
      background-color: #fff;
    }

    .f_hover {
      color: #f2f2f2 !important;
    }

    .f2_hover {
      color: #56ccf2 !important;
    }
  }

  // .case_item.active::after {
  //   content: ' ';
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   background-image: url('http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop.png');
  //   background-size: 100% 100%;
  //   width: 25%;
  //   height: 100%;
  //   transition: background 0.3s ease-in;
  //   // z-index: -9999;
  // }
}

.case_bj_item {
  opacity: 0;
  transition: all 0.5s;
}

.case_bj_item.active {
  transition: background 0.2s ease-in;
  position: absolute;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  width: 25% !important;
  height: 100%;
  animation: vanishIn 1.5s;
  opacity: 1;
}

.case_bj_item:nth-child(1).active {
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop1.png");
}

.case_bj_item:nth-child(2).active {
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop2.png");
}

.case_bj_item:nth-child(3).active {
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop3.png");
}

.case_bj_item:nth-child(4).active {
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop4.png");
}

.case_bj_item:nth-child(5).active {
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop5.png");
}

@keyframes vanishIn {
  0% {
    opacity: 0;
    // height: 0%;
  }

  // 25% {
  //   opacity: 0.25;
  // }
  // 50% {
  //   opacity: 0.5;
  // }
  // 75% {
  //   opacity: 0.75;
  // }

  100% {
    opacity: 1;
    // height: 100%;
  }
}

.close-enter-active {
  transition: opacity 1.5s;
}

.close-leave-active {
  transition: opacity 0.2s;
}

.open-enter,
.open-leave-to,
.close-enter,
.close-leave-to {
  opacity: 0;
}

.swiper_box {
  width: 100%;

  .swiper {
    height: 3.625rem;
    width: 100%;
  }
}

.swiper-slideb:nth-child(1) {
  width: 2.9063rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(2) {
  width: 6.25rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(3) {
  width: 5.625rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(4) {
  width: 5.6563rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(5) {
  width: 7.9063rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(6) {
  width: 16.3438rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(7) {
  width: 13.7188rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(8) {
  width: 8.4688rem;
  height: 2.2813rem;
}

.swiper-slideb:nth-child(9) {
  width: 6.6563rem;
  height: 2.2813rem;
}

.img_box {
  // width: 203px;
  height: 2.2813rem;

  // line-height: 116px;
  // background: #F2F2F2;
  // text-align: center;
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .img-cooperate {
    height: 100%;
  }
}

.swiper-button-nexts,
.swiper-button-prevs {
  width: 0.3438rem;
  height: 0.5456rem;
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/nexts.png");
  background-size: 100% 100%;
}

.swiper-button-prevs {
  transform: rotate(180deg);
}

.news_box {
  .NEWS_icon {
    position: absolute;
    right: 0;
    top: 1.25rem;
    height: 6.6563rem;
    z-index: -1;
  }

  .news_nav_item {
    padding: 0.3125rem 1.1563rem;
    margin-right: 0.1563rem;
    position: relative;

    .active_icon_fix {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: -0.4688rem;
    }
  }

  .news_nav_item.active {
    background: #2f80ed;
    color: #fff !important;
  }

  .news_list {
    margin-top: 2rem;
    padding: 0;

    .min_news_item_box {
      padding: 0.8125rem 1.5938rem 0 1.5938rem;
      height: 7.3125rem;
      margin-bottom: 0.2188rem;
      // margin-left: 12px
    }

    .max_news_item_box {
      height: 7.3125rem;
      margin-bottom: 0.2188rem;
      // margin-right: 12px
    }
  }
}

@media (min-width: 200px) and (max-width: 768px) {
  .max_title_home.T1_Bold {
    font-size: 1.8072rem;
  }

  .max_title_home.T5_Regular {
    font-size: 0.7229rem;
  }

  .learn_more_btn {
    width: 5.5313rem;
    height: 1.8188rem;
    line-height: 1.8188rem;
    font-size: 0.7229rem;

    .arrow_icon {
      width: 1.25rem;
    }
  }

  ::v-deep .swiper-pagination-bullet {
    width: 0.5422rem !important;
    height: 0.5422rem !important;
  }

  .container_fuwu {
    display: none;
  }

  .section_fuwu {
    padding-bottom: 3.9759rem !important;
  }

  .container_h5_fuwu {
    display: block;

    .fuwu_header {
      margin-left: 0.7229rem;

      .SERVICE_logo {
        width: 12.4096rem;
        height: 3.3133rem;
        object-fit: scale-down !important;
      }

      .fuw_title {
        font-size: 1.4458rem;
      }

      .fuw_title_tips {
        font-size: 0.7229rem;
        margin-bottom: 1.2048rem !important;
      }
    }

    .swiper-slide {
      // width: 16.9277rem !important;
      width: calc(16.9277rem - 1.6265rem) !important;
      margin-left: 0.7229rem;
    }

    .service_item {
      // width: 8.7813rem;
      width: calc(16.9277rem - 1.6265rem);
      min-height: 23.5542rem;
      box-shadow: 0px 0.125rem 0.125rem 0px rgba(0, 0, 0, 0.25);
      border-radius: 0.2813rem;

      padding: 1.6265rem;

      &::before {
        width: 100%;
        height: 0.3217rem !important;
        border-radius: 0px 0px 0.1563rem 0.1563rem;
      }

      .service_item_title {
        font-size: 1.2048rem !important;
        margin-bottom: 0.6024rem;
      }

      .service_item_tips {
        font-size: 0.8434rem;
        width: 11.5663rem;
        min-height: 9.0361rem;
      }

      // .f2_hover {
      //   font-size: .8434rem;
      //   min-height: 12.0482rem;
      // }

      .farrow_icon {
        width: 1.988rem !important;
        height: 1.988rem;
        margin-right: 0.9036rem;
      }

      .ljie_tips {
        font-size: 1.0843rem;
      }

      // .f_hover {
      //   font-size: .9639rem;
      // }
    }

    .pagination_finx {
      margin: 0 auto;
      margin-top: 2.4096rem;
    }

    .i_hover1 {
      width: 2.0938rem !important;
      margin-bottom: 2.4096rem;
    }

    .i_hover2,
    .i_hover3,
    .i_hover4 {
      width: 2.75rem !important;
      margin-bottom: 2.4096rem;
    }

    .i_hover5 {
      width: 2.5625rem !important;
      margin-bottom: 2.4096rem;
    }
  }

  .case_container_pc {
    display: none;
  }

  .case_section {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background-color: #fff;
  }

  .case_container_h5 {
    display: block;
    padding: 0;

    .row_header {
      padding-right: var(--bs-gutter-x, 0.75rem);
      padding-left: var(--bs-gutter-x, 0.75rem);
      margin-left: 0.7229rem;

      .case_title {
        font-size: 1.4458rem;
      }

      .case_title_tips {
        font-size: 0.7229rem;
        margin-bottom: 0 !important;
      }

      .text_box {
        padding: 0 !important;
      }

      .PROJECT_icon {
        width: 13.253rem;
        height: 3.3133rem;
        object-fit: scale-down;
      }
    }

    .T0_Bold {
      font-size: 1.875rem;
    }

    .text_box {
      position: unset !important;
      align-items: flex-start;

      .gengduo_icon {
        width: 3.8434rem;
        margin-top: 0.6024rem;
        margin-bottom: 1.2048rem;
      }
    }

    .T5_Regular {
      font-size: 0.625rem;
    }

    .case_item_max_bj {
      width: 100%;
      padding: 3.253rem 2.7711rem;
      position: relative;
      z-index: 1;

      .case_item {
        width: 100%;
        height: 17.3494rem;
        margin-top: 0;
        background: #ffffff;
        box-shadow: 0px 0.241rem 0.241rem 0px rgba(0, 0, 0, 0.25);
        border-radius: 0.5422rem;
        padding-bottom: 1.4458rem;
        position: relative;

        &.active {
          opacity: 1;
          background: #2f80ed;
          transition: all 1.5s;

          .biaotixiangmu {
            color: #fff !important;
          }

          .min_h {
            font-size: 0.9639rem;
            color: #56ccf2 !important;
          }

          &::after {
            content: " ";
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 0.3012rem;
            background: #fff;
            border-radius: 0px 0px 0.3012rem 0.3012rem;
          }
        }

        &::after {
          content: " ";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 0.3012rem;
          background: #2f80ed;
          border-radius: 0px 0px 0.3012rem 0.3012rem;
        }

        .c_logo {
          width: 2.2289rem !important;
          height: 1.6867rem;
          margin-bottom: 1.2048rem;
          object-fit: scale-down;
        }

        .xiangmumc {
          font-size: 0.9639rem;
          color: #e0e0e0 !important;
        }

        .biaotixiangmu {
          font-size: 1.0843rem;
          margin-bottom: 1.3253rem !important;
        }

        .min_h {
          font-size: 0.9639rem;
        }

        .medal_icon {
          width: 4.0361rem !important;
          height: 3.6747rem;
        }

        .case_learn_more_btn {
          margin-top: 1.4458rem;
          font-size: 0.7229rem;
          width: 6.3855rem;
          margin: 0;

          margin-left: 2.4096rem;

          height: 1.988rem;
          line-height: 1.988rem;

          .arrow_left_icon {
            width: 1.9277rem !important;
          }
        }
      }
    }

    .case_item_max_bj:nth-child(1).active {
      &::after {
        content: "";
        background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop1.png");
        background-size: cover;
        opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        animation: backgroundname 2s;
      }
    }

    .case_item_max_bj:nth-child(2).active {
      &::after {
        content: "";
        background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop2.png");
        background-size: cover;
        opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        animation: backgroundname 2s;
      }
    }

    .case_item_max_bj:nth-child(3).active {
      &::after {
        content: "";
        background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop3.png");
        background-size: cover;
        opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        animation: backgroundname 2s;
      }
    }

    .case_item_max_bj:nth-child(4).active {
      &::after {
        content: "";
        background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop4.png");
        background-size: cover;
        opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        animation: backgroundname 2s;
      }
    }

    .case_item_max_bj:nth-child(5).active {
      &::after {
        content: "";
        background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop5.png");
        background-size: cover;
        opacity: 1;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
        animation: backgroundname 2s;
      }
    }
  }

  @keyframes backgroundname {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .news_box_pc {
    display: none;
  }

  .new_section {
    padding-top: 2.4096rem !important;
    padding-bottom: 1.4458rem !important;
  }

  .news_box_h5 {
    display: block;

    .new_header {
      margin-left: 0.7229rem;

      .new_title {
        font-size: 1.4458rem;
      }

      .NEWS_icon {
        width: 8.8554rem;
        height: 3.3133rem;
        object-fit: scale-down;
        top: auto;
        right: -0.3241rem;
      }
    }

    .new_name {
      font-size: 0.7229rem;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .news_item_box {
      padding-left: 0.6024rem;
    }

    .new_time {
      font-size: 0.6627rem;
    }

    .max_news_item_box {
      margin-right: 0.1807rem;
      margin-bottom: 0.1807rem;
      height: 5.7831rem !important;
    }

    .min_news_item_box {
      padding-left: 0.6024rem !important;
      padding-right: 0.6024rem !important;
      height: 5.7831rem !important;
    }

    .n1_max_img {
      padding: 0 !important;
    }
  }

  ::v-deep .img-thumbnail {
    padding: 0;
    border-radius: 0;
  }
}
</style>
