<template>
  <div>
    <header>
      <img
        src="http://si54wj5yl.hn-bkt.clouddn.com/contact/max_bj.png"
        class="img-fluid w-100"
        alt="..."
      />
    </header>

    <section class="section" style="padding-top: 1.8438rem">
      <div class="container">
        <header class="container_header">
          <h1 class="T0_Bold text-B0 container_header_title">人材招聘</h1>
          <span class="T5_Regular text-B2 container_header_tips"
            >如果您对技术充满热情，并希望充满活力和专业精神的团队合作，请联系我们</span
          >

          <img
            src="http://si54wj5yl.hn-bkt.clouddn.com/contact/RECRUIT.png"
            class="img-fluid RECRUIT_icon"
            alt="..."
          />
        </header>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-1
            variant="info"
          >
            <span>工艺工程师</span>
            <b-icon
              icon="chevron-down"
              :class="list[0].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-1`"
              @input="
                (e) => {
                  carInput(e, 0);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">职位详情</h3>
                <span class="T5_Regular text-B1 mb-18">1.工作性质：全职</span>
                <span class="T5_Regular text-B1 mb-18"
                  >2.工作地点:北京、成都</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >3.学历要求：本科及以上</span
                >
                <span class="T5_Regular text-B1 mb-18">4.工作经验：不限</span>
                <span class="T5_Regular text-B1 mb-18"
                  >5.专业要求：化工工程、化学工程与工艺、石油化工</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >6.外语要求：英语熟练</span
                >
                <span class="T5_Regular text-B1 mb-18">7.待遇：面谈</span>
                <h3 class="T3_Bold text-B0 pt_10 mb-22">任职资格：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）化工工程或相关专业大学本科（及以上）应届毕业；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）所学主要课程：化工原理、物理化学、有机化学、分析化学、无机化学、反应工程、机械制图等并成绩优良。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）
                  熟练应用AUTOCAD软件、Office软件，熟悉PROII、AEPEN等模拟软件者优先考虑。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）大学英语水平四级以上、并具有良好的英语听说读写能力者优先考虑。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（5）
                  责任心强，具有较强的组织能力和语言表达能力，适于从事团队作业，能够较好的与人沟通和协作。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（6） 能适应高强度的工作节奏、肯吃苦、抗压能力强。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（7）能适应经常出差以及现场服务等工作。
                </span>

                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-2
            variant="info"
          >
            <span>储运工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[1].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-2`"
              @input="
                (e) => {
                  carInput(e, 1);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">职位详情</h3>
                <span class="T5_Regular text-B1 mb-18">1.工作性质：全职</span>
                <span class="T5_Regular text-B1 mb-18"
                  >2.工作地点:北京、成都</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >3.学历要求：本科及以上</span
                >
                <span class="T5_Regular text-B1 mb-18">4.工作经验：不限</span>
                <span class="T5_Regular text-B1 mb-18"
                  >5.专业要求：油气储运、化工工程、化学工程与工艺、石油化工</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >6.外语要求：英语熟练</span
                >
                <span class="T5_Regular text-B1 mb-18">7.待遇：面谈</span>
                <h3 class="T3_Bold text-B0 pt_10 mb-22">任职资格：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）油气储运、化工工艺等相关专业本科以上；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）具备3年以上化工/石化工程项目油气储运、化工工程设计经验，具有甲级设计院工作经历者优先考虑；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）熟悉石化工程设计流程，能独立承担石化储运系统设计及配管设计；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）具有强烈的责任心、较好的沟通表达能力、团队合作能力；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（5）熟练使用Auto
                  CAD等绘图软件，如有PDSOFT、PDMS、PDS、SMARTPLANT等三维管道设计软件，ASPEN模拟软件优先。</span
                >

                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-3
            variant="info"
          >
            <span>给排水工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[2].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-3`"
              @input="
                (e) => {
                  carInput(e, 2);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">职位详情</h3>
                <span class="T5_Regular text-B1 mb-18">1.工作性质：全职</span>
                <span class="T5_Regular text-B1 mb-18"
                  >2.工作地点:北京、成都</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >3.学历要求：本科及以上</span
                >
                <span class="T5_Regular text-B1 mb-18">4.工作经验：不限</span>
                <span class="T5_Regular text-B1 mb-18"
                  >5.专业要求：给排水专业、环保专业、化学工程与工艺</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >6.外语要求：英语熟练</span
                >
                <span class="T5_Regular text-B1 mb-18">7.待遇：面谈</span>
                <h3 class="T3_Bold text-B0 pt_10 mb-22">岗位职责：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）负责完成石油化工或煤化工项目的给排水专业方案设计工作；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）负责完成给排水专业的计算和施工图绘制工作；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）负责完成给排水专业各阶段设计工作；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）配合工艺等主导专业完成可研、基础设计、详细设计或工艺包开发等工作；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（5）配合工程部、项目管理部等其他部门完成设计概算和现场技术服务等工作。</span
                >

                <h3 class="T3_Bold text-B0 pt_10 mb-22">任职要求：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）熟悉掌握石油化工或煤化工给排水设计、熟练使用工程制图软件；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）掌握国家和行业给排水工程设计规范及标准；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）能独立完成本专业的设计计算任务；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）给排水专业、本科以上学历、5年以上工作经验；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（5）5年以上石化行业工程设计、工程施工图设计经历。</span
                >

                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-4
            variant="info"
          >
            <span>管道工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[3].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-4`"
              @input="
                (e) => {
                  carInput(e, 3);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">职位详情</h3>
                <span class="T5_Regular text-B1 mb-18">1.工作性质：全职</span>
                <span class="T5_Regular text-B1 mb-18">2.工作地点:北京</span>
                <span class="T5_Regular text-B1 mb-18"
                  >3.学历要求：本科及以上</span
                >
                <span class="T5_Regular text-B1 mb-18">4.工作经验：不限</span>
                <span class="T5_Regular text-B1 mb-18"
                  >5.专业要求：化学工程与工艺、过程装备与控制工程等</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >6.外语要求：具备一定的英语应用能力</span
                >
                <span class="T5_Regular text-B1 mb-18">7.待遇：面谈</span>
                <h3 class="T3_Bold text-B0 pt_10 mb-22">任职资格：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）工作年限5年以上，取得中级工程师职称；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）熟悉本专业的设计规范/标准；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）化工工艺、化工机械、油气储运及相关专业；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）熟练使用AutoCAD及Office办公软件；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（5）责任心强，具有良好的团队合作精神和协调沟通能力</span
                >

                <h3 class="T3_Bold text-B0 pt_10 mb-22">岗位职责：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）根据工艺条件进行装置设备布置及管道布置设计；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）使用PDS、PDMS、S3D系统建立三维模型；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）管道支吊架设计；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）向其他专业出相关设计条件；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（5）出管道ISO图及平面布置图；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（6）管道材料汇总；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（7） 配合现场施工安装处理和解决施工中遇到的问题。</span
                >

                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-5
            variant="info"
          >
            <span>土建结构工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[4].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-5`"
              @input="
                (e) => {
                  carInput(e, 4);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">职位详情</h3>
                <span class="T5_Regular text-B1 mb-18">1.工作性质：全职</span>
                <span class="T5_Regular text-B1 mb-18"
                  >2.工作地点:北京、成都</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >3.学历要求：本科及以上</span
                >
                <span class="T5_Regular text-B1 mb-18">4.工作经验：不限</span>
                <span class="T5_Regular text-B1 mb-18"
                  >5.专业要求：土木工程</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >6.外语要求：具备一定的英语应用能力</span
                >
                <span class="T5_Regular text-B1 mb-18">7.待遇：面谈</span>
                <h3 class="T3_Bold text-B0 pt_10 mb-22">任职要求：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）熟悉国家规范及AutoCAD绘图软件、PKPM计算软件。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）具有准确计算工程量的能力，熟悉工艺流得程，掌握材料市场价格。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）5年以上工作经验，并取得中级职称证书</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）相关专业工作优先考虑</span
                >

                <h3 class="T3_Bold text-B0 pt_10 mb-22">岗位职责：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）协助制定工程项目的结构设计计划，完成工程项目中结构设计相关方案。</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）根据各专业人员提供的设计资料，拟订结构设计计划，编写专业设计调研报告；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）编写设计说明书和图纸目录；
                </span>
                <span class="T5_Regular text-B1 mb-20"
                  >（4）承担方案设计中的结构设计工作；
                </span>
                <span class="T5_Regular text-B1 mb-20"
                  >（5）对设计图纸的结构方面进行初审；
                </span>
                <span class="T5_Regular text-B1 mb-20"
                  >（6）完成结构施工的技术交底工作；
                </span>
                <span class="T5_Regular text-B1 mb-20"
                  >（7）配合现场施工、质量检验、竣工验收、设计回访及设计技术总结工作。</span
                >

                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-6
            variant="info"
          >
            <span>设备工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[5].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-6`"
              @input="
                (e) => {
                  carInput(e, 5);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">职位详情</h3>
                <span class="T5_Regular text-B1 mb-18">1.工作性质：全职</span>
                <span class="T5_Regular text-B1 mb-18"
                  >2.工作地点:北京、成都</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >3.学历要求：本科及以上</span
                >
                <span class="T5_Regular text-B1 mb-18">4.工作经验：不限</span>
                <span class="T5_Regular text-B1 mb-18"
                  >5.专业要求：过程装备与控制工程、机械制造及其自动化</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >6.外语要求：具备一定的英语应用能力</span
                >
                <span class="T5_Regular text-B1 mb-18">7.待遇：面谈</span>
                <h3 class="T3_Bold text-B0 pt_10 mb-22">岗位责任：</h3>
                <span class="T5_Regular text-B1 mb-20"
                  >（1）具有10年以上石化工业施工管理方面工作经验（有石化项目工作经验优先）</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（2）熟悉掌握石化行业安装技术；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（3）在一个以上大、中型炼化或石化类项目中担任过设备工程师工作；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（4）本科及以上学历，机械及相关专业。具有工程师职称</span
                >

                <span class="T5_Regular text-B1 mb-20"
                  >（5）了解ISO9001体系和HSE管理体系；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（6）具备良好的组织协调能力、沟通能力及团队协作能力；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（7）良好的语言表达、交流能力；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（8）熟练掌握常用的计算机办公软件；</span
                >
                <span class="T5_Regular text-B1 mb-20"
                  >（9）能承受较紧张的工作压力和长期现场工作。</span
                >

                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-7
            variant="info"
          >
            <span>项目经理工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[6].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-7`"
              @input="
                (e) => {
                  carInput(e, 6);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <!-- <h3 class="T3_Bold text-B0 mb-22">（1）具有10年以上石化工业施工管理方面工作经验</h3> -->
                <span class="T5_Regular text-B1 mb-18"
                  >（1）具有10年以上石化工业施工管理方面工作经验</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（2）具有二个及以上相关岗位的工作经历</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（3）熟悉石化工业施工技术规范/标准；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（4）大专及以上学历，中级或以上技术职称；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（5）具备良好的组织协调能力、沟通能力及团队协作能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（6）良好的语言表达、交流能力</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（7）熟练掌握常用的计算机办公软件；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（8）具有良好的职业道德和敬业精神；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（9）身体健康、能承受较紧张的工作压力和长期现场工作。</span
                >
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-8
            variant="info"
          >
            <span>施工管理工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[7].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-8`"
              @input="
                (e) => {
                  carInput(e, 7);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <h3 class="T3_Bold text-B0 mb-22">岗位责任：</h3>
                <span class="T5_Regular text-B1 mb-18"
                  >（1）具有10年以上石化工业施工管理方面工作经验（有石化项目工作经验优先）；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（2）熟悉石化工业施工技术规范/标准；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（3）大专及以上学历，中级或以上技术职称；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（4）有丰富的管理业绩；（至少有一个SEI总承包项目经验）</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（5）具备良好的组织协调能力、沟通能力及团队协作能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（6）良好的语言表达、交流能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（7）熟练掌握常用的计算机办公软件；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（8）具有良好的职业道德和敬业精神；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（9）身体健康、能承受较紧张的工作压力和长期现场工作。</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（10）熟悉SEI的施工管理程序。</span
                >
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-9
            variant="info"
          >
            <span>安全工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[8].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-9`"
              @input="
                (e) => {
                  carInput(e, 8);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <!-- <h3 class="T3_Bold text-B0 mb-22">岗位责任：</h3> -->
                <span class="T5_Regular text-B1 mb-18"
                  >（1）具有专职安全管理人员资质证书（具国家注册安全工程师资格证书优先）；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（2）具有5年以上施工现场HSE管理方面工作经验（有石化项目工作经验优先）；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（3）熟悉HSE法律法规及规范/标准和现场HSE执行各要素；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（4）掌握本职工作所需的安全生产知识，具备事故预防和应急处理能力，具有一定自身安全意识、防护能力和风险识别能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（5）大专及以上学历，中级或以上技术职称；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（6）具备良好的组织协调能力、沟通能力及团队协作能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（7）良好的语言表达、交流能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（8）熟练掌握常用的计算机办公软件；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（9）具有良好的职业道德和敬业精神；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（10）身体健康、能承受较紧张的工作压力和长期现场工作。</span
                >
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-10
            variant="info"
          >
            <span>质量工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[9].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-10`"
              @input="
                (e) => {
                  carInput(e, 9);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <!-- <h3 class="T3_Bold text-B0 mb-22">岗位责任：</h3> -->
                <span class="T5_Regular text-B1 mb-18">
                  （1）具有10年以上石化工业施工管理方面工作经验（有石化项目工作经验优先）；管道：机械或相关专业毕业；钢结构、设备：相关专业；土建：土建专业毕业；电气：相关专业毕业，检查证书；仪表：相关专业毕业，检查证书；设备：机械或相关专业毕业；
                </span>
                <span class="T5_Regular text-B1 mb-18"
                  >（2）熟悉石化工业施工技术规范/标准；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（3）大专及以上学历，中级或以上技术职称；</span
                >
                <span class="T5_Regular text-B1 mb-18">
                  （4）熟练掌握ISO9001和其他质量管理体系和HSE管理体系；有丰富的管理业绩；
                </span>
                <span class="T5_Regular text-B1 mb-18"
                  >（5）具备良好的组织协调能力、沟通能力及团队协作能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（6）良好的语言表达、交流能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（7）熟练掌握常用的计算机办公软件；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（8）具有良好的职业道德和敬业精神；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（9）身体健康、能承受较紧张的工作压力和长期现场工作。</span
                >
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-11
            variant="info"
          >
            <span>控制工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[10].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-11`"
              @input="
                (e) => {
                  carInput(e, 10);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <!-- <h3 class="T3_Bold text-B0 mb-22">岗位责任：</h3> -->
                <span class="T5_Regular text-B1 mb-18">
                  （1）具有3年以上计划管理方面工作经验（有石化项目工作经验优先）；
                </span>
                <span class="T5_Regular text-B1 mb-18">
                  （2）熟悉计划控制方面的理论知识；熟练掌握常用的计划管理软件P3/P6、Project，建立进度检测系统并进行跟踪、检测；
                </span>
                <span class="T5_Regular text-B1 mb-18"
                  >（3）大专及以上学历，中级或以上技术职称</span
                >
                <span class="T5_Regular text-B1 mb-18">
                  （4）具备良好的组织协调能力、沟通能力及团队协作能力；
                </span>
                <span class="T5_Regular text-B1 mb-18"
                  >（5）良好的语言表达、交流能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（6）具有良好的职业道德和敬业精神；</span
                >
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>

        <div class="accordion_box" id="accordionExample" role="tablist">
          <div
            block
            class="T2_Bold accordion_title d-flex"
            v-b-toggle.accordion-12
            variant="info"
          >
            <span>文档工程师 </span>
            <b-icon
              icon="chevron-down"
              :class="list[11].state ? 'zc_icons' : 'zc_icon'"
            ></b-icon>
          </div>
          <b-card no-body :class="`mb-1 card_max_box`">
            <b-collapse
              :id="`accordion-12`"
              @input="
                (e) => {
                  carInput(e, 11);
                }
              "
              accordion="my-accordion"
              role="tabpanel"
            >
              <div class="accordion-body d-flex flex-column bg_E8F2F3">
                <!-- <h3 class="T3_Bold text-B0 mb-22">岗位责任：</h3> -->
                <span class="T5_Regular text-B1 mb-18">
                  （1）具有石油化工行业项目建设经验者优先，有1年以上相关工作经验；
                </span>
                <span class="T5_Regular text-B1 mb-18">
                  （2）熟悉微软系列操作平台及应用软件，熟知SEI关于文档收发的流程，了解各专业之间的文件传送关系；
                </span>
                <span class="T5_Regular text-B1 mb-18"
                  >（3）大专及以上学历；</span
                >
                <span class="T5_Regular text-B1 mb-18">
                  （4）具备良好的组织协调能力、沟通能力及团队协作能力；
                </span>
                <span class="T5_Regular text-B1 mb-18"
                  >（5）良好的语言表达、交流能力；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（6）具有良好的职业道德和敬业精神；</span
                >
                <span class="T5_Regular text-B1 mb-18"
                  >（7）身体健康、能承受较紧张的工作压力和长期现场工作。</span
                >
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="T5_Regular text-B1 learn_more_btn"
                >
                  了解更多
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/heisejaint.png"
                    class="img-fluid arrow_icon"
                    alt="..."
                  />
                </div>
              </div>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </section>

    <section
      class="section bg_E8F2F3 enrollment_box"
      style="padding-top: 1.8438rem"
    >
      <div class="container">
        <header class="container_header">
          <h1 class="T0_Bold text-B0 container_header_title" style="z-index: 9">
            课程招生
          </h1>
          <span
            class="T5_Regular text-B2 container_header_tips"
            style="z-index: 9; position: relative"
            >如果您对技术充满热情，并希望充满活力和专业精神的团队合作，请联系我们</span
          >

          <img
            src="http://si54wj5yl.hn-bkt.clouddn.com/contact/RECRUITs.png"
            class="img-fluid RECRUITs_icon"
            alt="..."
          />
        </header>

        <div class="row">
          <div
            class="col-lg-6 enrollment_item"
            @click="activeIndex = 1"
            :class="activeIndex == 1 ? 'active' : ''"
            style="margin-top: 1.5625rem"
          >
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Rectangle 187.png"
              class="img-fluid"
              alt="..."
            />

            <div class="enrollment_info">
              <div class="left_box">
                <h2 class="T2_Bold max_title">计划控制</h2>
                <span class="T6_Regular state text_item">1.机构设置</span>
                <span class="T6_Regular state text_item"> 2.机构设置</span>
                <span class="T6_Regular state text_item"> 3.机构设置</span>
                <span class="T6_Regular state text_item"> 4.机构设置</span>
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="d-flex flex-row align-items-center btn_box"
                >
                  <span class="T6_Regular state"> 咨询课程详情</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                    width="24"
                    class="img-fluid state"
                    alt="..."
                  />
                </div>
              </div>
              <div class="d-flex flex-row w-100 justify-content-end codestate">
                <div class="d-flex flex-column">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Group 1410085627.png"
                    width="81px"
                    class="img-fluid codestate"
                    alt="..."
                  />
                  <span class="T6_Regular codestate">微信扫码咨询</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 enrollment_item"
            @click="activeIndex = 2"
            :class="activeIndex == 2 ? 'active' : ''"
            style="margin-top: 1.5625rem"
          >
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Rectangle 188.png"
              class="img-fluid"
              alt="..."
            />
            <div class="enrollment_info">
              <div class="left_box">
                <h2 class="T2_Bold max_title">费用控制</h2>
                <span class="T6_Regular state text_item">1.机构设置</span>
                <span class="T6_Regular state text_item"> 2.机构设置</span>
                <span class="T6_Regular state text_item"> 3.机构设置</span>
                <span class="T6_Regular state text_item"> 4.机构设置</span>
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="d-flex flex-row align-items-center btn_box"
                >
                  <span class="T6_Regular state"> 咨询课程详情</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                    width="24"
                    class="img-fluid state"
                    alt="..."
                  />
                </div>
              </div>
              <div class="d-flex flex-row w-100 justify-content-end codestate">
                <div class="d-flex flex-column">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Group 1410085627.png"
                    width="81px"
                    class="img-fluid codestate"
                    alt="..."
                  />
                  <span class="T6_Regular codestate">微信扫码咨询</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 enrollment_item"
            @click="activeIndex = 3"
            :class="activeIndex == 3 ? 'active' : ''"
            style="margin-top: 1.5625rem"
          >
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Rectangle 188.png"
              class="img-fluid"
              alt="..."
            />

            <div class="enrollment_info">
              <div class="left_box">
                <h2 class="T2_Bold max_title">HSE管理</h2>
                <span class="T6_Regular state text_item">1.机构设置</span>
                <span class="T6_Regular state text_item"> 2.机构设置</span>
                <span class="T6_Regular state text_item"> 3.机构设置</span>
                <span class="T6_Regular state text_item"> 4.机构设置</span>
                <div
                  @click="goAssignBlock('weizhi', 30)"
                  class="d-flex flex-row align-items-center btn_box"
                >
                  <span class="T6_Regular state"> 咨询课程详情</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                    width="24"
                    class="img-fluid state"
                    alt="..."
                  />
                </div>
              </div>
              <div class="d-flex flex-row w-100 justify-content-end codestate">
                <div class="d-flex flex-column">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Group 1410085627.png"
                    width="81px"
                    class="img-fluid codestate"
                    alt="..."
                  />
                  <span class="T6_Regular codestate">微信扫码咨询</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-lg-6 enrollment_item"
            @click="activeIndex = 4"
            :class="activeIndex == 4 ? 'active' : ''"
            style="margin-top: 1.5625rem"
          >
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Rectangle 186.png"
              class="img-fluid"
              alt="..."
            />
            <div class="enrollment_info">
              <div class="left_box">
                <h2 class="T2_Bold max_title">质量管理培训</h2>
                <span class="T6_Regular state text_item">1.机构设置</span>
                <span class="T6_Regular state text_item"> 2.机构设置</span>
                <span class="T6_Regular state text_item"> 3.机构设置</span>
                <span class="T6_Regular state text_item"> 4.机构设置</span>
                <div class="d-flex flex-row align-items-center btn_box">
                  <span class="T6_Regular state"> 咨询课程详情</span>
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png"
                    width="24"
                    class="img-fluid state"
                    alt="..."
                  />
                </div>
              </div>
              <div
                @click="goAssignBlock('weizhi', 30)"
                class="d-flex flex-row w-100 justify-content-end codestate"
              >
                <div class="d-flex flex-column">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/contact/Group 1410085627.png"
                    width="81px"
                    class="img-fluid codestate"
                    alt="..."
                  />
                  <span class="T6_Regular codestate">微信扫码咨询</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section" style="padding-top: 3.7188rem">
      <div class="container d-flex justify-content-center">
        <div class="row col-lg-9" ref="weizhi">
          <div class="col-lg-6 contact_item d-flex flex-column">
            <span class="T2_Regular text-C1">联系我们</span>
            <span class="T6_Regular text-B3"
              >我们将会在1-2个工作日对您的情况进行回复</span
            >

            <div class="form_box">
              <div class="d-flex justify-content-between">
                <div class="col-6">
                  <span class="text-B2 T5_Regular">您的称呼</span>
                  <div class="input_box">
                    <input
                      type="text"
                      v-model="form.name"
                      class="T6_Regular"
                      placeholder="您的称呼"
                      maxlength="10"
                    />
                  </div>
                </div>
                <div class="col-6" style="margin-left: 2.5%">
                  <span class="text-B2 T5_Regular">联系方式</span>
                  <div class="input_box input_box_rigth">
                    <input
                      type="text"
                      v-model="form.phone"
                      class="T6_Regular"
                      placeholder="电话号码"
                      maxlength="11"
                    />
                  </div>
                </div>
              </div>

              <div class="col-12">
                <span class="text-B2 T5_Regular">邮箱地址</span>
                <div class="input_box youx_input_box">
                  <input
                    type="text"
                    v-model="form.email"
                    class="T6_Regular"
                    placeholder="邮箱"
                    maxlength="40"
                  />
                </div>
              </div>

              <div>
                <span class="text-B2 T5_Regular">您需咨询的内容</span>
                <div class="d-flex flex-wrap">
                  <b-form-checkbox-group
                    id="checkbox-group-2"
                    class="d-flex flex-wrap"
                    v-model="selected"
                    name="flavour-2"
                  >
                    <b-form-checkbox value="1">
                      <span class="text-B3 T58_Regular checkbox_name"
                        >项目管理</span
                      >
                    </b-form-checkbox>
                    <b-form-checkbox value="2">
                      <span class="text-B3 T58_Regular checkbox_name">
                        技术服务</span
                      >
                    </b-form-checkbox>
                    <b-form-checkbox value="3">
                      <span class="text-B3 T58_Regular checkbox_name"
                        >工程设计</span
                      >
                    </b-form-checkbox>
                    <b-form-checkbox value="4">
                      <span class="text-B3 T58_Regular checkbox_name"
                        >课程招生</span
                      >
                    </b-form-checkbox>
                    <b-form-checkbox value="5">
                      <span class="text-B3 T58_Regular checkbox_name"
                        >人材招聘</span
                      >
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </div>
              </div>

              <div>
                <span class="text-B2 T5_Regular">您的想法</span>
                <div>
                  <textarea
                    v-model="form.messages"
                    name=""
                    id=""
                    cols="30"
                    class="T6_Regular textarea_input"
                    rows="10"
                    placeholder="说出你的想法"
                  ></textarea>
                </div>
              </div>

              <footer class="form_footer">
                <div class="T5_Regular form_footer_btn" @click="SubmitForm">
                  立即提交
                </div>
              </footer>
            </div>
          </div>
          <div class="col-lg-6 contact_item">
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/contact/gsxinio.png"
              class="img-fluid gsxinio_logo"
              alt="..."
            />
          </div>
        </div>
      </div>
    </section>

    <!-- contact -->
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "AboutUs",
  data() {
    return {
      list: [
        { name: "工艺工程师", state: false, id: 1 },
        { name: "储运工程师", state: false, id: 2 },
        { name: "给排水工程师", state: false, id: 3 },
        { name: "管道工程师", state: false, id: 4 },
        { name: "土建结构工程师", state: false, id: 5 },
        { name: "设备工程师", state: false, id: 6 },
        { name: "项目经理工程师", state: false, id: 7 },
        { name: "施工管理工程师", state: false, id: 8 },
        { name: "安全工程师", state: false, id: 9 },
        { name: "质量工程师", state: false, id: 10 },
        { name: "文档工程师", state: false, id: 11 },
        { name: "文档工程师", state: false, id: 12 },
      ],
      group: [],
      selected: [],
      show: false,
      listIndex: 0,
      activeIndex: 0,

      form: {
        name: "",
        phone: "",
        email: "",
        messages: "",
      },
    };
  },
  mounted() {},

  methods: {
    /***
     * 提交联系我们邮箱联系方式
     */
    SubmitForm() {
      console.log(this.form, this.selected);
      let consultingArr = [];
      this.selected.forEach((item) => {
        if (item == 1) {
          consultingArr.push("项目管理");
        } else if (item == 2) {
          consultingArr.push("技术服务");
        } else if (item == 1) {
          consultingArr.push("工程设计");
        } else if (item == 1) {
          consultingArr.push("课程招生");
        } else if (item == 1) {
          consultingArr.push("人才服务");
        }
      });
      let message = `
      名称：${this.form.name}<br/>
      电话：${this.form.phone}<br/>
      邮箱：${this.form.email}<br/>
      咨询：${consultingArr.toString(",")}<br/>
      想法：${this.form.messages}<br/>
      `;

      this.form.message = message;
      console.log(consultingArr, message);
      let that = this
      axios
        .post(
          "http://3s9w568349.51vip.biz/index.php/api/mailbox/submit",
          this.form
        )
        .then(
          () => {
            that.form = {}
          },
          (error) => {
            console.log(error);
          }
        );
    },
    goAssignBlock(el) {
      this.$refs[el].scrollIntoView(); //模块内容高度
    },
    carInput(state, i) {
      var lists = JSON.parse(JSON.stringify(this.list));
      const list = lists.map((item, index) => {
        if (index === i) {
          item.state = state;
        } else {
          item.state = false;
        }
        return item;
      });
      this.list = JSON.parse(JSON.stringify(list));
    },
    scrollToTop() {
      let that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      this.welcomeshow = true;
      this.welcomeshow1 = true;
      this.welcomeshow2 = true;
      this.welcomeshow3 = true;
      this.delay = "0s";
      if (that.scrollTop > 50) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container_header {
  display: inline-grid;

  .container_header_title {
    margin: 0;
    height: 2.1563rem;
    line-height: 2.1563rem;
  }

  .container_header_tips {
    margin-top: 0.0938rem;
  }

  .RECRUIT_icon {
    position: absolute;
    // height: 213px;
    right: 0;
    top: 1.875rem;
    z-index: -1;
    width: 40.7188rem;
    height: 6.7813rem;
    object-fit: fill;
  }

  .RECRUITs_icon {
    position: absolute;
    height: 6.6563rem;
    right: 0;
    top: 1.25rem;
    z-index: 1;
  }
}

.accordion_box {
  margin-top: 1.75rem;

  .zc_icon {
    transform: rotate(-90deg);
    transition: all 0.5s;
  }

  .zc_icons {
    transform: rotate(0deg);
    transition: all 0.5s;
  }

  .card_max_box {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    border-radius: 0;
  }

  .card_header {
    background: #fff;
    border-radius: 0;
    border: none;
  }

  .accordion_title {
    color: #171717;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0.625rem;
  }
}

.enrollment_item {
  position: relative;
  z-index: 2;

  .enrollment_info {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0.375rem;
    color: #fff;
    padding: 0 1.25rem;
    justify-content: flex-end !important;
    padding-bottom: 0.8438rem;

    .max_title {
      margin-bottom: 0.4688rem;
    }

    .text_item {
      display: none !important;
      margin-bottom: 0.3125rem;
      opacity: 0;
      transition: all 0.2s;
    }

    .left_box {
      width: 80%;

      .btn_box {
        display: none !important;
        width: 4.9375rem;
        padding: 0.125rem 0.25rem;
        border-radius: 0.9375rem;
        justify-content: space-between;
      }
    }

    .state {
      display: block;
    }

    .codestate {
      display: none;
    }
  }
}

//
.enrollment_item:hover {
  .enrollment_info {
    display: flex;
    flex-direction: row;
    transition: all 1s;
    width: calc(100% - .75rem);
    height: 100%;
    backdrop-filter: blur(10px);
    left: 12px;
    bottom: 0;
    align-items: flex-end;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    .max_title {
      font-size: 1.0938rem;
      transition: all 0.5s;
    }

    .state {
      display: block;
    }

    .text_item {
      display: block !important;
      opacity: 1;
      transition: all 0.2s;
    }

    .left_box {
      width: 80%;

      .btn_box {
        width: 4rem;
        padding: 0.125rem 0.25rem;
        border: 1px solid #fff;
        border-radius: 0.9375rem;
        justify-content: space-between;
        // display: block !important;
        display: flex !important;
        flex-wrap: nowrap !important;
        .img-fluid{
            width: .75rem !important;
        }
      }
    }
  }
}

.enrollment_info .left_box .btn_box:hover {
  background-color: #2f80ed;

  .codestate {
    display: block !important;
  }
}

.gsxinio_logo {
  width: 8.5313rem;
  height: 24rem;
}

.form_box {
  width: 16.9063rem;
  // height: 20.9375rem;
  background: #ffffff;
  border-radius: 0.4063rem;
  border: 1px solid #e0e0e0;
  margin-top: 0.9375rem;
  padding: 0.7813rem 1rem 0.9375rem 1.4063rem;

  .input_box {
    width: 95%;
    height: 1.375rem;
    border-radius: 0.2188rem;
    border: 1px solid #bdbdbd;
    line-height: 1.375rem;
  }

  .youx_input_box {
    width: 100%;
    height: 1.375rem;
  }

  .custom-checkbox {
    margin-right: 2.5rem;
    margin-top: 0.4688rem;
    display: flex;
    flex-direction: row;
    align-content: center;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .checkbox_name {
    margin-left: 0.5625rem;
  }

  .textarea_input {
    width: 100%;
    height: 4.625rem;
    border-radius: 4px;
    border: 1px solid #bdbdbd;
  }

  .form_footer {
    border-top: 1px solid #e0e0e0;
    padding-top: 0.625rem;

    .form_footer_btn {
      width: 100%;
      height: 1.5938rem;
      background: #2f80ed;
      border-radius: 0.2188rem;
      color: #ffffff;
      text-align: center;
      line-height: 1.5938rem;
    }
  }
}

input {
  border: none;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  margin: 0;
  background: transparent;
  text-align: center;
}

.learn_more_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(4.3125rem - 0.4063rem);
  height: 1.2188rem;
  // line-height: 1.2188rem;
  text-align: center;
  border-radius: 0.8438rem;
  border: 0.0313rem solid #333333;
  padding-left: 0.4063rem;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;

  .img-fluid {
    transition: all 0.2s ease;
    transform: rotate(0deg);
  }

  .arrow_icon {
    width: 1rem;
  }
}

.learn_more_btn:hover {
  //   background-color: #2F80ED;
  //   color: #ffff;

  background-color: #2f80ed;
  color: #fff !important;
  border: 0.0313rem solid #2f80ed !important;

  .img-fluid {
    transform: rotate(90deg);
    transition: all 0.2s ease;
    content: url("http://si54wj5yl.hn-bkt.clouddn.com/images/arrow.png");
  }
}

::v-deep .custom-control-label {
  // line-height: 1 ;
  display: inline-flex !important;
  // height: .5rem !important ;
}

@media (min-width: 200px) and (max-width: 768px) {
  .contact_item:nth-child(2) {
    margin-top: 0.625rem;
  }
  .RECRUIT_icon,
  .RECRUITs_icon {
    width: auto !important;
    height: 2.2824rem !important;
  }
  .RECRUITs_icon {
    top: auto !important;
  }
  .form_box {
    width: 100%;
  }

  .custom-checkbox {
    margin-right: 2.5rem;
    display: flex;
    align-items: center;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  .gsxinio_logo {
    width: 100%;

    height: auto;
  }
  ::v-deep .custom-control-label {
    // line-height: 1 ;
    height: 0.5rem !important ;
  }
  // .btn_box{
  //     width: 5rem !important;
  // }
}
</style>

<style></style>
