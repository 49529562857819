<template>
  <div
    class="Full_screen_bj"
    :class="`Full_screen_bj${caseIndx + 1} topindex${topindex}`"
  >
    <div class="Full_screen w-100 d-flex flex-column container">
      <transition>
        <div class="xiangmu_box T8_Regular">
          <div
            class="left_box"
            @click="changeTopindex(1)"
            :class="topindex == 1 ? 'active' : ''"
          >
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/xiangmu_min_logo.png"
              class="xiangmu_min_logo"
              alt=""
            />
            <span class="text-B7">项目管理</span>
          </div>
          <div
            class="rigth_box T8_Regular"
            @click="changeTopindex(2)"
            :class="topindex == 2 ? 'active' : ''"
          >
            <img
              src="http://si54wj5yl.hn-bkt.clouddn.com/images/xiangmu_min_logo.png"
              class="xiangmu_min_logo"
              alt=""
            />
            <span class="text-B7">设计业务</span>
          </div>
        </div>
      </transition>

      <h1
        class="T1_Bold text-B6 one_max_tile"
        style="margin-bottom: 31px; margin-top: 29px"
      >
        {{ showList[caseIndx].title }}
      </h1>
      <span class="T6-Regular one_max_time text-B6">{{
        showList[caseIndx].time
      }}</span>

      <div class="text_list_box">
        <p
          class="T3_Regular text-B6 text_list_p"
          v-for="(item, index) in showList[caseIndx].pText"
          :key="index"
        >
          <span class="T4_Bold label">{{ item.label }}</span> {{ item.text }}
        </p>
      </div>

      <div class="row">
        <div class="d-flex justify-content-between case_pc">
          <div
            class="d-flex flex-column col-12 case_item"
            @click="caseChange(index)"
            :class="index == caseIndx ? 'active' : ''"
            v-for="(item, index) in showList"
            :key="index"
          >
            <img
              :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/c_logo.png`"
              class="img-fluid c_logo"
              alt="..."
            />

            <h4 class="T5_Bold text-B6 mb-15 f_hover xiangmu">项目名称</h4>

            <h3 class="T4_Bold text-B6 mb-15 f_hover xiangmu_tips">
              {{ item.title }}
            </h3>

            <span class="T6_Regular text-B2 mb-28 min_h f2_hover">
              {{ item.time }}
            </span>

            <footer v-show="caseIndx == index" class="f_hover">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medals.png"
                class="img-fluid medal_icon"
                alt="..."
              />
            </footer>
            <footer v-show="caseIndx != index" class="f_hover">
              <img
                src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medal.png"
                class="img-fluid medal_icon"
                alt="..."
              />
            </footer>
          </div>
        </div>

        <div class="d-flex justify-content-between case_h5">
          <swiper
            class="swiperS w-100 h-100"
            ref="mySwiper"
            :options="maxSwiperOption"
          >
            <swiper-slide
              v-for="(item, index) in showList"
              :key="index"
              class="swiper-slides"
            >
              <div
                class="d-flex flex-column col-12 case_item"
                :class="index == caseIndx ? 'active' : ''"
              >
                <img
                  :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/home/c_logo.png`"
                  class="img-fluid c_logo"
                  alt="..."
                />

                <h4 class="T5_Bold text-B6 mb-15 f_hover xiangmu">项目名称</h4>

                <h3 class="T4_Bold text-B6 mb-15 f_hover xiangmu_tips">
                  {{ item.title }}
                </h3>

                <span class="T6_Regular text-B2 mb-28 min_h f2_hover">
                  {{ item.time }}
                </span>

                <footer v-show="caseIndx == index" class="f_hover">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medals.png"
                    class="img-fluid medal_icon"
                    alt="..."
                  />
                </footer>
                <footer v-show="caseIndx != index" class="f_hover">
                  <img
                    src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/medal.png"
                    class="img-fluid medal_icon"
                    alt="..."
                  />
                </footer>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>

    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop1.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop2.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop3.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop4.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop5.png" style="display: none" />

    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_1.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_2.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_3.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_4.png" style="display: none" />
    <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_5.png" style="display: none" />
  </div>
</template>
<script>
let vm;

export default {
  name: "AboutUs",
  data() {
    return {
      btnFlag: false,
      navShow: false,
      navIndex: 0,
      caseIndx: 0,
      maxSwiperOption: {
        on: {
          slideChange: function () {
            // console.log(this.activeIndex)
            vm.caseIndx = this.activeIndex;
          },
        },
      },

      showList: [],

      caseList: [
        {
          title: "中科合资广东炼化一体化项目",
          time: "2018.07-2021.12",
          pText: [
            {
              label: "炼油装置:",
              text: "1000万吨/年常减压、200万吨/轻烃回收、440万吨/渣油加氢脱硫等19套装置。",
            },
            {
              label: "化工装置:",
              text: "包括年产80万吨的蒸汽裂解、35万吨的高密度聚乙烯、35万吨的聚丙烯（ST）、10万吨的EVA、20万吨的聚丙烯（引进）等11套装置",
            },
            {
              label: "涉及部门:",
              text: "施工管理部、设计管理部、质量管理部、控制部，项目七分部由我公司独立管理。",
            },
          ],
        },
        {
          title: "天津石化炼油改造项目",
          time: "2018.07-2021.06",
          pText: [
            {
              label: "主要装置:",
              text: "新建260万吨/年渣油加氢装置、280万吨/年催化裂化装置、双脱装置、70万吨/年气分装置、120 万吨/年S-Zorb装置和10万标立/时天然气制氢、200吨/小时污水汽提等7套装置。",
            },
            {
              label: "涉及部门:",
              text: "除业主单位配置项目经理外，施工管理部、采购部、安全部、行政综合部、费用控制部、质量管理部人员全部由我公司配置。",
            },
          ],
        },
        {
          title: "恒逸文莱PMB石油化工项目",
          time: "2018.01-2019.05",
          pText: [
            {
              label: "主要装置:",
              text: "炼油装置包括年处理800万吨的常减压、150万吨的轻烃回收及产品精制、220万吨的加氢裂化、150万吨的芳烃联合、220万吨的柴油加氢、130万吨的煤油加氢及轻石脑油异构化、100万吨的灵活焦化、12万吨的硫磺回收等装置，及其相应的电站、码头、罐区、海水淡化等配套工程。",
            },
            {
              label: "涉及部门:",
              text: "施工管理部、质量管理部、控制部、采购部，参与4个项目分部项目管理。",
            },
          ],
        },
        {
          title: "古雷石化炼化一体化项目",
          time: "2019.02-2023.03",
          pText: [
            {
              label: "主要装置:",
              text: "化工装置包括年产80万吨的蒸汽裂解、55万吨的裂解汽油加氢、35万吨的芳烃抽提、13万吨的丁二烯抽提、30万吨的乙烯-醋酸乙烯树脂（EVA）、10/70万吨的环氧乙烷/乙二醇（EO/EG）、60万吨的苯乙烯（SM）、35万吨的聚丙烯（PP）、10万吨的热塑性弹性体（SBS），以及供水、供电、汽电联产、空分、储运等配套工程。",
            },
            {
              label: "涉及部门:",
              text: "施工管理部、质量管理部、控制部、采购部，参与七个项目分部项目管理。",
            },
          ],
        },
        {
          title: "镇海炼化项目",
          time: "2020.04-当前",
          pText: [
            {
              label: "主要装置:",
              text: "镇海炼化扩建1500万吨/年炼油120万吨/年乙烯项目（乙烯部分，含新建120万吨/年乙烯裂解、60万吨/年裂解汽油加氢、40万吨/年芳烃抽提、16万吨/年丁二烯抽提、10/4万吨/年MTBE/丁烯-1、20万吨/年高密度聚乙烯、80万吨/年乙二醇、20万吨/年聚丙烯、36/80万吨/年环氧丙烷/苯乙烯、16万吨/年聚烯烃弹性体、16万标方/小时造气制氢、5万吨/年硫磺回收）",
            },
            {
              label: "涉及部门:",
              text: "除业主单位配置项目经理外，采购部、安全部、行政综合部、费用控制部人员全部由我公司配置。",
            },
          ],
        },
      ],

      sjList: [
        {
          title: "中沙（天津）石化有限公司 ",
          time: "2015.04-2018.5",
          pText: [
            {
              label: " ",
              text: "投资15亿元的中沙（天津）石化有限公司, 在这个项目中公司团队奋力合作，攻克了各种难题和困难，顺利完成交付任务。     ",
            },
            {
              label: "涉及内容：",
              text: "130万吨/年乙烯改造项目，100万吨/年乙烯装置是中沙(天津)石化有限公司的核心装置，也是中石化建设的第一套百万吨级乙烯装置，采用ST/Lummus合作工艺专利技术。     ",
            },
          ],
        },
        {
          title: "烟台市龙口裕龙岛",
          time: "2016.01-2017.6",
          pText: [
            {
              label: " ",
              text: "烟台市龙口裕龙岛总投资1274亿元4000万吨/年裕龙岛炼化一体化项目",
            },
            {
              label: "涉及内容:",
              text: "涉及化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、化工中间原料罐区、乙烯球罐区、乙烯球罐区、丙烯球罐区、酸碱罐区、2#岛汽车装卸车设施及为化工中间原料罐区配套的两个机柜室和两个配电间等十多个复杂工区的设计任务，进一步验证了公司的综合能力。",
            },
          ],
        },
        {
          title: "亿元的恒力石化（大连）化工有限公司",
          time: "2019.03-2020.04",
          pText: [
            {
              label: " ",
              text: "大连长兴岛经济区总投资约209亿元的恒力石化（大连）化工有限公司",
            },
            {
              label: "涉及内容:",
              text: "150万吨/年乙烯工程项目",
            },
          ],
        },
        {
          title: "中石化宁波镇海炼化有限公司",
          time: "2020.04-2021.08",
          pText: [
            {
              label: " ",
              text: "中石化宁波镇海炼化有限公司总投资约267.87亿元，",
            },
            {
              label: "涉及内容:",
              text: "建设规模为扩建1500万吨/年炼油120万吨/年乙烯项目",
            },
          ],
        },
        {
          title: "福建漳州古雷石化基地项目",
          time: "2020.04-2023.09",
          pText: [
            {
              label: " ",
              text: "总投资230.7亿元的炼化一体化项目百万吨级乙烯及下游深加工装置项目",
            },
            {
              label: "涉及内容:",
              text: "80万吨/年蒸汽裂解、35万吨/年芳烃抽提装臵及55万吨/年裂解汽油加氢等11套化工装置",
            },
          ],
        },
      ],

      bjUrl: "http://si54wj5yl.hn-bkt.clouddn.com/images/AboutUs/AboutUs_bj.png",

      topindex: 1,
    };
  },
  created() {
    vm = this;
    let topindex = this.$route.query.topindex;
    console.log(topindex, 321312);
    if (topindex == undefined) {
      this.topindex = 1;
    } else {
      this.topindex = topindex;
    }

    if (this.topindex == 2) {
      this.showList = this.sjList;
    } else {
      this.showList = this.caseList;
    }
  },
  methods: {
    changeTopindex(index) {
      this.topindex = index;
      if (this.topindex == 2) {
        this.showList = this.sjList;
      } else {
        this.showList = this.caseList;
      }
    },
    onSlideChange(e) {
      console.log(e);
    },
    caseChange(index) {
      this.caseIndx = index;
      this.bjUrl = "http://si54wj5yl.hn-bkt.clouddn.com/images/dynamic/max_logo.png";
    },
    changShow() {
      this.navShow = !this.navShow;
    },
  },
};
</script>
<style lang="less" scoped>
h1 {
  margin: 0 !important;
}

.one_max_tile {
  font-size: 1.25rem;
  margin-top: 0.9063rem !important;
  margin-bottom: 0.1875rem !important;
}

.one_max_time {
  font-size: 0.5rem;
}

.text_list_box {
  margin-top: 1.5313rem;
  min-height: 7.625rem;

  .text_list_p {
    width: 100%;
    font-size: 0.625rem;
    margin-bottom: 0.9375rem;
    line-height: 1.0625rem;

    .label {
      font-size: 0.75rem;
    }
  }
}

.Full_screen {
  padding: 5rem 0 0 0;
  // position: relative;
  min-height: 100vh;

  .xiangmu_box {
    display: flex;
    align-items: center;

    // width: 6.0938rem ;
    // height: ;
    .left_box,
    .rigth_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.4375rem;
      height: 1.0625rem;
      text-align: center;
      background: #bdbdbd;
      font-size: 0.4688rem !important;
      border-radius: 0px 0.0938rem 0.0938rem 0px;
      padding: 0 0.2188rem;
      overflow: hidden;
      transition: all 0.8s;

      span {
        display: inline-block;
        min-width: 2.0625rem;
      }
    }

    .left_box {
      border-radius: 0.0938rem 0px 0px 0.0938rem;
    }

    .active {
      width: 3.4375rem;
      background: #2f80ed;
      transition: all 0.8s;

      .xiangmu_min_logo {
        display: block;
        transition: all 1s;
        opacity: 1;
      }
    }

    .xiangmu_min_logo {
      display: none;
      width: 0.6563rem;
      margin-right: 0.4375rem;
      opacity: 0;
    }
  }
}

.Full_screen_bj {
  transition: all 0.9s;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0;
  padding-bottom: 2.1875rem;
}

.Full_screen_bj1 {
  opacity: 1;
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop1.png");
  &.topindex2 {
    background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_1.png");
  }
}

.Full_screen_bj2 {
  opacity: 1;
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop2.png");
  &.topindex2 {
    background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_2.png");
  }
}

.Full_screen_bj3 {
  opacity: 1;
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop3.png");
  &.topindex2 {
    background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_3.png");
  }
}

.Full_screen_bj4 {
  opacity: 1;
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop4.png");
  &.topindex2 {
    background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_4.png");
  }
}

.Full_screen_bj5 {
  opacity: 1;
  background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop5.png");
  &.topindex2 {
    background-image: url("http://si54wj5yl.hn-bkt.clouddn.com/images/home/bjzhaop_5.png");
  }
}

.case_item {
  margin-top: 25px;
  padding: 0.5313rem 0.875rem 1.1563rem 0.7813rem;
  border: 0.125rem solid #f2f2f2;
  width: 18.8888%;
  // width: 9.25rem;
  // height: 10.3125rem;
  // width: 9.2188rem;
  // height: 7.6563rem;
  // margin-right: .75rem;

  .c_logo {
    width: 1.4375rem;
    height: 1.0938rem;
    margin-bottom: 0.9375rem;
  }

  .xiangmu {
    font-size: 0.5625rem;
    margin-bottom: 0.4688rem;
  }

  .xiangmu_tips {
    font-size: 0.625rem;
  }

  .min_h {
    font-size: 0.5rem;
  }

  .medal_icon {
    width: 1.9688rem;
    height: 1.7813rem;
  }
}

.case_item.active {
  transition: all 0.4s ease-out;
  background: #2f80ed;
  box-shadow: 0px 4px 11px 0px rgba(155, 155, 155, 0.25);
  border: 0.125rem solid #2f80ed;
  position: relative;
  border: none;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.1875rem;
    bottom: 0;
    background: #fff;
    left: 0;
    // border-radius: .1563rem .1563rem 0px 0px;
  }

  .f2_hover {
    color: #56ccf2 !important;
  }
}

.nav_item {
  width: 90px;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #4f4f4f;
  background: #828282;
  font-size: 16px;
  font-weight: 400;
  font-family: "HarmonyOS Sans TC Regular" !important;
  margin-top: 12px;
}

.nav_item.active {
  width: 100px;
  height: 28px;
  line-height: 28px;
  padding-left: 20px;
  background: #2f80ed;
  color: #f2f2f2;
  transition: all 0.4s ease-out;

  font-size: 16px;
  font-weight: 500 !important;
  font-family: "HarmonyOS Sans TC Bold" !important;
}

.case_h5 {
  display: none !important;
}

@media (min-width: 200px) and (max-width: 768px) {
  .case_pc {
    display: none !important;
  }

  .Full_screen {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    padding-top: 5rem;
    min-height: auto !important;
    padding-bottom: 3.4063rem;

    .text_list_box {
      min-height: 9.625rem;

      .text_list_p {
        margin-bottom: 0.9375rem;
      }
    }
  }

  .case_h5 {
    display: block !important;
    // height: 20.625rem;

    .case_item {
      width: 100% !important;
    }
  }

  .text_list_box {
    .text_list_p {
      line-height: 1.25rem;
    }
  }
}
</style>
