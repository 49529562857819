<template>
    <div>
        <header style="position: relative;min-height: 21.6875rem ;" class="h5_max_header">
            <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/dynamic/max_logo.png" class="img-fluid w-100" alt="...">

            <!-- <div class="header_nav_box">
                <span class="header_nav_item T2_Regular text-B7 ">项目资讯</span>
                <span class="header_nav_item T2_Regular text-B7 ">课程资讯</span>
            </div> -->
        </header>



        <section class="section " style="padding-top: 3.7188rem;">
            <div class="container">

                <div class="row ">
                    <div @click="infoFun(index)"
                        class="col-lg-4 col-10  project_item d-flex flex-column justify-content-center "
                         v-for="(item, index) in list" :class="index > 2 ? 'wow animate__backInUp' : '' " :key="index">

                        <img :src="`http://si54wj5yl.hn-bkt.clouddn.com/images/project/project_logo_${index + 1}.png`" class="image_logo"
                            alt="">
                        <span class="text-B2 type_name T5_Regular"> 项目资讯</span>
                        <div class="info">
                            <h5 class="T2_Bold text-B0 project_name " style="margin: 0;">{{ item.name }}</h5>
                        </div>

                        <div class="ljie_box">
                            <img src="http://si54wj5yl.hn-bkt.clouddn.com/images/home/farrow.png" class="farrow_icon" alt="">
                            <span class="T4_Bold ljie">了解更多</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- contact -->
    </div>
</template>


<script>
export default {
    name: "AboutUs",
    data() {
        return {
            list: [
                { name: '风机一响，黄金万两！中科炼化一重量级装置成功启用' },
                { name: '攻坚场景美如画 最美一线石化人' },
                { name: '海派克参管中科EVA装置中交' },
                { name: '海派克海南自贸大楼项目开工仪式' },
                { name: '海峡两岸合资，古雷项目投产!' },
                { name: '年产值超600亿!今天投产!' },
                { name: '喜讯  古雷炼化一体化项目4套重要生产装置顺利中交' },
                { name: '中科炼化“云投产' },
                { name: '中科炼化废碱处理装置率' },
                { name: '中科炼化港口30万吨级原油码头投用' },
                { name: '中科炼化首批国六汽柴油出厂' },
                { name: '中科炼化首批化工产品入市' },
                { name: '中科项目建设投产表彰大会' }
            ],
            listIndex: 0
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.$wow.init()
        })
    },

    methods: {
        infoFun(index) {
            this.$router.push({ path: '/information/index', query: { i: index } })
        },
        scrollToTop() {
            let that = this;
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            that.scrollTop = scrollTop;
            this.welcomeshow = true
            this.welcomeshow1 = true
            this.welcomeshow2 = true
            this.welcomeshow3 = true
            this.delay = '0s'
            if (that.scrollTop > 50) {
                that.btnFlag = true;
            } else {
                that.btnFlag = false;
            }
        },
    },
};
</script>


<style lang="less" scoped>
.header_nav_box {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(18, 31, 40, 0.38);
    padding: .625rem 6.25rem;
    display: flex;
    // justify-content: space-around;

    .header_nav_item {
        display: inline-block;
        min-width: 2.9688rem;
        height: .875rem;
        line-height: .875rem;
        padding-bottom: .125rem;
        border-bottom: .0625rem solid transparent;
        margin-right: 3.125rem;
    }

    .header_nav_item:nth-child(1) {
        border-bottom: .0625rem solid #FFFFFF;
    }
}

.project_item {
    margin-bottom: 1.75rem;
    border: 1px solid #D6D6D6;
    // width: 12.5313rem;
    border-radius: .2188rem;
    margin-right: 2.5938rem;
    padding-right: 0;
    width: calc(33.333% - 1.8rem);
    overflow: hidden;

    &:nth-child(3n) {
        margin-right: 0;
    }

    .image_logo {
        // width: 100%;
        // height: 7.75rem;
        // width: 12.5313rem;
        width: 100%;
        height: 5.6875rem;
        object-fit: cover;
        border-radius: .2188rem .2188rem 0px 0px;
        background: #D9D9D9;
        cursor: pointer;
        transition: all .4s;
    }

    &:hover {
        .image_logo {
            transform: scale(1.2);
            transition: all .5s;
        }
    }

    .type_name {
        margin-top: 1.0938rem;
        margin-bottom: .625rem;
        margin-left: 1.0313rem;
    }

    .info {
        margin-left: 1.0313rem;
        // width: 9.125rem;
        padding-right: 2.1875rem;

        .project_name {
            font-size: .8125rem;
            height: 1.875rem;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            line-clamp: 2;
            -webkit-line-clamp: 2;
            /* 设置显示的行数 */

        }
    }

    .ljie_box {
        display: flex;
        align-items: center;
        margin-left: 1.0313rem;
        margin-top: 2.625rem;
        margin-bottom: .8438rem;

        .farrow_icon {
            width: 1rem;
            height: 1.0313rem;
            margin-right: .4688rem;
        }

        .ljie {
            color: #2F80ED;
        }
    }
}

.row>*:nth-child(1n) {
    padding-left: 0 !important;
}

.row>*:nth-child(3n) {
    padding-right: 0 !important;
}

@media (min-width: 200px) and (max-width: 768px) {
    .h5_max_header {
        min-height: 100% !important;
    }

    .section {
        padding-top: 2rem !important;
    }

    .project_item {
        width: 83%;
        margin: 0 auto;
        margin-bottom: 1.25rem;

        &:nth-child(3n) {
            margin-left: 0;
            margin: 0 auto;
            margin-bottom: 1.25rem;
        }
    }

    .header_nav_box {
        padding-left: 8.5%;

    }

    .header_nav_item {
        display: inline-block !important;
        width: 3.9759rem !important;
        margin-right: 1.8072rem !important;
    }
}
</style>